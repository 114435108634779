import _ from 'lodash';
import {
    ApolloClient,
    DocumentNode,
    FetchResult,
    gql,
    InternalRefetchQueriesInclude,
    MutationFunctionOptions,
    MutationHookOptions,
    MutationResult,
} from '/@utils/error-handling/apollo-hooks-wrappers/custom-apollo-hooks';
import { DataPaginationParamsInput } from '../generated/graphql-types';

export interface BachMutationHookOptions<TD, TV>
    extends MutationHookOptions<TD, TV> {
    setNextGenNoShouldReplaceCurrent?: boolean;
    setVisualizationsLoading?: boolean;
    setPhrasesLoading?: boolean;
    dataPaginationParams?: DataPaginationParamsInput;
    shouldRefetchData?: boolean;
    customRefetchQueriesFn?: (
        client: ApolloClient<any>,
        result: FetchResult<TD>,
    ) => InternalRefetchQueriesInclude;
    awaitForQueries?: boolean;
    setPinboardLoading?: boolean;
    trackPinboardMutationProgress?: boolean;
    waitUntilMutationIsInFlight?: boolean;
}

export interface BachMutationFunctionOptions<TD, TV>
    extends MutationFunctionOptions<TD, TV> {
    setNextGenNoShouldReplaceCurrent?: boolean;
    setVisualizationsLoading?: boolean;
    setPhrasesLoading?: boolean;
    setPinboardLoading?: boolean;
    trackPinboardMutationProgress?: boolean;
}

export type BachMutationTuple<TData, TVariables> = [
    (
        options?: BachMutationFunctionOptions<TData, TVariables>,
    ) => Promise<FetchResult<TData>>,
    MutationResult<TData>,
];

export const bachSessionKeyQuery: DocumentNode = gql`
    {
        sessionId
        genNo
    }
`;

export const bachSessionWithAcSessionKeyQuery: DocumentNode = gql`
    {
        sessionId
        genNo
        acSession {
            sessionId
            genNo
        }
    }
`;

export const bachPinboardSessionKeyQuery: DocumentNode = gql`
    {
        sessionId
        genNo
        contextBookSessions {
            contextBookId
            sessionId {
                sessionId
                genNo
            }
        }
    }
`;

export const getStatefulMutationParams = (
    isPinboardSession: boolean,
    includeAcSessionInfo: boolean,
) => {
    if (isPinboardSession) {
        return {
            typeName: 'BachPinboardSession',
            sessionKeyQuery: bachPinboardSessionKeyQuery,
            nestedSessionKeys: ['contextBookSessions'],
        };
    }

    return {
        typeName: 'BachSessionId',
        sessionKeyQuery: includeAcSessionInfo
            ? bachSessionWithAcSessionKeyQuery
            : bachSessionKeyQuery,
        nestedSessionKeys: includeAcSessionInfo ? ['acSession'] : [],
    };
};
