import { ReactElement } from 'react';
import { matchRoutes, NonIndexRouteObject } from 'react-router-dom';

const findRouteForPath = (routes: NonIndexRouteObject[], path: string) => {
    const matchingRoutes = matchRoutes(routes, path);
    // On the matches found, the last path is the most matched nested path.
    const matchingRoute =
        matchingRoutes && matchingRoutes[matchingRoutes.length - 1];
    return matchingRoute && matchingRoute.route;
};

export const preloadComponents = async (routes: NonIndexRouteObject[]) => {
    const currentPath = window.location.hash.split('#')[1] || '';
    const matchingRoute = findRouteForPath(routes, currentPath);

    // Component here is an instance of react-lazy-with-preload wrapper, which should have the preload method defined on it.
    const Component: any =
        matchingRoute &&
        (matchingRoute.Component ||
            (matchingRoute.element as ReactElement)?.type);
    if (Component && Component.preload) {
        Component.preload();
    }
};
