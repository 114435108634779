import { create } from '@thoughtspot/logger';
import { UserAction, userActionTypeEnum } from '@thoughtspot/metrics';
import _ from 'lodash';
import {
    Answer,
    ChartViz,
    HeadlineViz,
    TableViz,
    Visualization,
} from '/@services/generated/graphql-types';

const logger = create('pinboard-perf-metrics');

export const markEvent = (
    eventName: string | number,
    suffix: string = null,
    value: any = null,
) => {
    if (_.isFunction(performance?.mark)) {
        let markName = new UserAction(eventName).name;
        if (suffix) markName = `${markName}-${suffix}`;
        let markedEvent;
        if (value) {
            markedEvent = performance.mark(markName, {
                detail: { value },
            });
        } else {
            markedEvent = performance.mark(markName);
        }
        return markedEvent;
    }
    return null;
};

export const clearEventMark = (
    eventName: string | number,
    suffix: string = null,
): void => {
    if (_.isFunction(performance?.clearMarks)) {
        let markName = new UserAction(eventName).name;
        if (suffix) markName = `${markName}-${suffix}`;
        performance.clearMarks(markName);
    }
    return null;
};

export const getEventTimestamp = (
    eventName: string | number,
    suffix: string = null,
) => {
    if (_.isFunction(performance?.getEntriesByName)) {
        let markName = new UserAction(eventName).name;
        if (suffix) markName = `${markName}-${suffix}`;
        const markEntries = performance.getEntriesByName(markName);
        if (!_.isEmpty(markEntries)) return markEntries[0].startTime;
    }
    return null;
};

export const getEventValue = (
    eventName: string | number,
    suffix: string = null,
) => {
    if (_.isFunction(performance?.getEntriesByName)) {
        let markName = new UserAction(eventName).name;
        if (suffix) markName = `${markName}-${suffix}`;
        const markEntries = performance.getEntriesByName(markName);
        if (!_.isEmpty(markEntries)) {
            const markEntry = markEntries[0] as PerformanceMark;
            return markEntry.detail?.value;
        }
    }
    return null;
};

export const getEventsByName = (
    eventName: string | number,
    suffix: string = null,
) => {
    if (_.isFunction(performance?.getEntriesByName)) {
        let markName = new UserAction(eventName).name;
        if (suffix) markName = `${markName}-${suffix}`;
        const markEntries = performance.getEntriesByName(markName);
        return markEntries;
    }
    return null;
};

export const isEventMarked = (
    eventName: string | number,
    suffix: string = null,
) => {
    if (_.isFunction(performance?.getEntriesByName)) {
        let markName = new UserAction(eventName).name;
        if (suffix) markName = `${markName}-${suffix}`;
        const markEntries = performance.getEntriesByName(markName);
        return !_.isEmpty(markEntries);
    }
    return null;
};

export const eventDuration = (
    eventName: string,
    startEvent: string | number,
    endEvent: string | number,
    suffix: string = null,
    appendStart = true,
    appendEnd = true,
) => {
    if (_.isFunction(performance?.measure)) {
        try {
            if (
                ((appendStart && isEventMarked(startEvent, suffix)) ||
                    isEventMarked(startEvent)) &&
                ((appendEnd && isEventMarked(endEvent, suffix)) ||
                    isEventMarked(endEvent))
            ) {
                let startMarkName = new UserAction(startEvent).name;
                let endMarkName = new UserAction(endEvent).name;
                if (suffix) {
                    startMarkName = appendStart
                        ? `${startMarkName}-${suffix}`
                        : startMarkName;
                    endMarkName = appendEnd
                        ? `${endMarkName}-${suffix}`
                        : endMarkName;
                }
                const markEntries = performance.measure(
                    eventName,
                    startMarkName,
                    endMarkName,
                );
                const duration = markEntries.duration;
                performance.clearMeasures(eventName);
                return duration;
            }
        } catch (error) {
            logger.error(error);
            return null;
        }
    }
    return null;
};

export const getWebVitalsMetrics = (isRedirectedToPinboard: boolean) => {
    if (isRedirectedToPinboard) {
        return {};
    }
    const FCP = getEventValue(userActionTypeEnum.MIXPANEL_FCP);
    const LCP = getEventValue(userActionTypeEnum.MIXPANEL_LCP);
    const FID = getEventValue(userActionTypeEnum.MIXPANEL_FID);
    const TTFB = getEventValue(userActionTypeEnum.MIXPANEL_TTFB);
    const CLS = getEventValue(userActionTypeEnum.MIXPANEL_CLS);

    return {
        FCP,
        LCP,
        FID,
        TTFB,
        CLS,
    };
};

export const measureResourceLoadTimes = (): {
    maxCssLoadTime: number;
    maxJsLoadTime: number;
} => {
    const resourceEntries = performance.getEntriesByType(
        'resource',
    ) as PerformanceResourceTiming[];

    let maxCssLoadTime = 0;
    let maxJsLoadTime = 0;

    resourceEntries.forEach(entry => {
        if (entry.initiatorType === 'link' && entry.name.endsWith('.css')) {
            maxCssLoadTime = Math.max(maxCssLoadTime, entry.duration);
        } else if (
            entry.initiatorType === 'script' &&
            entry.name.endsWith('.js')
        ) {
            maxJsLoadTime = Math.max(maxJsLoadTime, entry.duration);
        }
    });

    return { maxCssLoadTime, maxJsLoadTime };
};

export const markCDWQueryTimeTaken = (
    data: Visualization,
    suffix: string,
): void => {
    const visualizationsData = data?.data;
    let queryPerfStats = null;

    if (Array.isArray(visualizationsData)) {
        // Handle the case when visualizationsData is an array.
        // This will be the case for single and multi query Chart visualization.
        queryPerfStats = visualizationsData[0]?.queryPerfStats;
    } else if (
        typeof visualizationsData === 'object' &&
        visualizationsData !== null
    ) {
        // Handle the case when visualizationsData is an object
        // This will be the case for Table visualization.
        queryPerfStats = visualizationsData?.queryPerfStats;
    }

    const queryTimeTakenCDW = queryPerfStats?.timeTakenCdw?.length
        ? Math.max(
              ...queryPerfStats.timeTakenCdw.map(
                  (item: any) => item.queryTimeTaken ?? null,
              ),
          )
        : null;
    const timeTakenDatamanager = queryPerfStats?.timeTakenDatamanager;

    markEvent(userActionTypeEnum.PINBOARD_VIZ_QUERY_TIME_TAKEN_CDW, suffix, {
        queryTimeTakenCDW,
        timeTakenDatamanager,
    });
};

export const getPinboardPerfMetrics = () => {
    const performanceWithMemory = performance as any;
    let memoryUsage: number = null;
    if (performanceWithMemory.memory) {
        const memoryInfo = performanceWithMemory.memory;
        memoryUsage = memoryInfo.usedJSHeapSize;
    }
    const appInitStartAbsoluteTimestamp = getEventTimestamp(
        userActionTypeEnum.APP_INITIALIZED,
    );

    let loadPinboardStartTimestamp = eventDuration(
        'loadPinboardStartTimestamp',
        userActionTypeEnum.APP_INITIALIZED,
        userActionTypeEnum.PINBOARD_LOADING_STARTED,
    );

    const loadPinboardStartAbsoluteTimestamp = getEventTimestamp(
        userActionTypeEnum.PINBOARD_LOADING_STARTED,
    );

    const pinboardLoadTime = eventDuration(
        'pinboardLoadTime',
        userActionTypeEnum.PINBOARD_LOADING_STARTED,
        userActionTypeEnum.PINBOARD_RENDER_COMPLETE,
    );

    const pinboardContentLoadTime = eventDuration(
        'pinboardContentLoadTime',
        userActionTypeEnum.PINBOARD_CONTENT_LOADING_STARTED,
        userActionTypeEnum.PINBOARD_RENDER_COMPLETE,
    );

    let loadPinboardSessionCallTimestamp = eventDuration(
        'loadPinboardSessionCallTimestamp',
        userActionTypeEnum.APP_INITIALIZED,
        userActionTypeEnum.LOAD_PINBOARD_SESSION_API_STARTED,
    );
    const loadPinboardSessionCallDuration = eventDuration(
        'loadPinboardSessionCallDuration',
        userActionTypeEnum.LOAD_PINBOARD_SESSION_API_STARTED,
        userActionTypeEnum.LOAD_PINBOARD_SESSION_API_COMPLETE,
    );

    const isRedirectedToPinboard = isEventMarked(
        userActionTypeEnum.REDIRECTED_TO_PINBOARD,
    );
    if (isRedirectedToPinboard) {
        loadPinboardStartTimestamp = eventDuration(
            'loadPinboardStartTimestamp',
            userActionTypeEnum.REDIRECTED_TO_PINBOARD,
            userActionTypeEnum.PINBOARD_LOADING_STARTED,
        );
        loadPinboardSessionCallTimestamp = eventDuration(
            'loadPinboardSessionCallTimestamp',
            userActionTypeEnum.REDIRECTED_TO_PINBOARD,
            userActionTypeEnum.LOAD_PINBOARD_SESSION_API_STARTED,
        );
    }

    const webVitalsMetrics = getWebVitalsMetrics(isRedirectedToPinboard);

    const { maxCssLoadTime, maxJsLoadTime } = measureResourceLoadTimes();

    return {
        memoryUsage,
        maxJsLoadTime,
        maxCssLoadTime,
        appInitStartAbsoluteTimestamp,
        loadPinboardStartAbsoluteTimestamp,
        isRedirectedToPinboard,
        loadPinboardStartTimestamp,
        pinboardLoadTime,
        pinboardContentLoadTime,
        loadPinboardSessionCallTimestamp,
        loadPinboardSessionCallDuration,
        ...webVitalsMetrics,
    };
};

export const getVizPerfMetrics = (suffix: string) => {
    const performanceWithMemory = performance as any;
    let memoryUsage: number = null;
    if (performanceWithMemory.memory) {
        const memoryInfo = performanceWithMemory.memory;
        memoryUsage = memoryInfo.usedJSHeapSize;
    }
    let vizLoadingStartTimestamp = eventDuration(
        'vizLoadingStartTimestamp',
        userActionTypeEnum.APP_INITIALIZED,
        userActionTypeEnum.PINBOARD_VIZ_LOADING_STARTED,
        suffix,
        false,
    );
    const isRedirectedToPinboard = isEventMarked(
        userActionTypeEnum.REDIRECTED_TO_PINBOARD,
    );
    if (isRedirectedToPinboard) {
        vizLoadingStartTimestamp = eventDuration(
            'vizLoadingStartTimestamp',
            userActionTypeEnum.REDIRECTED_TO_PINBOARD,
            userActionTypeEnum.PINBOARD_VIZ_LOADING_STARTED,
            suffix,
            false,
        );
    }
    const vizRenderTime = eventDuration(
        'vizRenderTime',
        userActionTypeEnum.PINBOARD_LOAD_CONTEXT_BOOK_STARTED,
        userActionTypeEnum.PINBOARD_VIZ_RENDER_COMPLETE,
        suffix,
    );
    const loadContextBookApiTime = eventDuration(
        'loadContextBookApiTime',
        userActionTypeEnum.PINBOARD_LOAD_CONTEXT_BOOK_STARTED,
        userActionTypeEnum.PINBOARD_LOAD_CONTEXT_BOOK_COMPLETE,
        suffix,
    );
    const vizRenderTimeAfterReceivingData = eventDuration(
        'vizRenderTimeAfterReceivingData',
        userActionTypeEnum.PINBOARD_LOAD_CONTEXT_BOOK_COMPLETE,
        userActionTypeEnum.PINBOARD_VIZ_RENDER_COMPLETE,
        suffix,
    );

    const webVitalsMetrics = getWebVitalsMetrics(isRedirectedToPinboard);
    const { queryTimeTakenCDW, timeTakenDatamanager } = getEventValue(
        userActionTypeEnum.PINBOARD_VIZ_QUERY_TIME_TAKEN_CDW,
        suffix,
    );

    return {
        memoryUsage,
        contextBookId: suffix,
        isRedirectedToPinboard,
        vizLoadingStartTimestamp,
        vizRenderTime,
        vizRenderTimeAfterReceivingData,
        loadContextBookApiTime,
        queryTimeTakenCDW,
        timeTakenDatamanager,
        ...webVitalsMetrics,
    };
};

export const markEventOnce = (eventName: string | number) => {
    const isFirstEventMarked = isEventMarked(eventName);
    if (!isFirstEventMarked) {
        markEvent(eventName);
    }
};

export const clearAllPinboardPerfMarks = () => {
    clearEventMark(userActionTypeEnum.LOAD_PINBOARD_SESSION_API_STARTED);
    clearEventMark(userActionTypeEnum.LOAD_PINBOARD_SESSION_API_COMPLETE);
    clearEventMark(userActionTypeEnum.PINBOARD_RENDER_COMPLETE);
    clearEventMark(userActionTypeEnum.PINBOARD_CONTENT_LOADING_STARTED);
    clearEventMark(userActionTypeEnum.PINBOARD_LOADING_STARTED);
    clearEventMark(userActionTypeEnum.PINBOARD_FIRST_LOAD_CONTEXT_BOOK_STARTED);
    clearEventMark(userActionTypeEnum.REDIRECTED_TO_PINBOARD);
};

export const clearAllPinboardVizPerfMarks = (suffix: string) => {
    clearEventMark(
        userActionTypeEnum.PINBOARD_LOAD_CONTEXT_BOOK_STARTED,
        suffix,
    );
    clearEventMark(
        userActionTypeEnum.PINBOARD_LOAD_CONTEXT_BOOK_COMPLETE,
        suffix,
    );
    clearEventMark(userActionTypeEnum.PINBOARD_VIZ_LOADING_STARTED, suffix);
    clearEventMark(userActionTypeEnum.PINBOARD_VIZ_RENDER_COMPLETE, suffix);
};
