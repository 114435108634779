import cx from 'classnames';
import React from 'react';
import FlexView from 'react-flexview';
import styles from './layout.module.scss';

/**
 * These are the Design System spacing symbols.
 * a: 4px, b: 8px, c: 12px, d: 16px, e:20px
 * f: 24px, g: 28px, h: 32px, i: 40px, j: 48px
 * k: 56px, l: 64px
 */
export type Spacing = 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'j';

type LayoutProps = FlexView.Props & {
    spacing?: Spacing;
    minHeight?: string | number;
    ref?: React.Ref<HTMLElement>;
};

type HorizontalLayoutProps = {
    vAlignContent?: 'stretch' | 'center' | 'bottom' | 'top';
} & Omit<LayoutProps, 'vAlignContent'>;

type VerticalLayoutProps = {
    hAlignContent?: 'stretch' | 'center' | 'bottom' | 'top';
} & Omit<LayoutProps, 'hAlignContent'>;

type AnyLayoutProps = LayoutProps | HorizontalLayoutProps | VerticalLayoutProps;

function fixProps<T extends AnyLayoutProps>(props: T): T {
    const fixedProps = { ...props };

    // https://github.com/buildo/react-flexview/issues/83
    if (fixedProps.basis === 0) {
        fixedProps.basis = '0px';
    }

    return fixedProps;
}

export const View: React.FC<LayoutProps> = React.forwardRef((props, ref) => {
    const { spacing, className, ...flexProps } = fixProps(props);
    const spacingClassName = spacing ? `spacing-${spacing}` : '';
    return (
        <FlexView
            ref={ref}
            className={cx(className, spacingClassName)}
            {...flexProps}
        />
    );
});

export const Horizontal: React.FC<HorizontalLayoutProps> = (
    props: HorizontalLayoutProps,
) => {
    const { vAlignContent, minHeight, ...rest } = fixProps(props);
    // alignItems do not support values such as "top" and "bottom"
    let vAlignContentValue = '';
    if (vAlignContent === 'top') {
        vAlignContentValue = 'flex-start';
    } else if (vAlignContent === 'bottom') {
        vAlignContentValue = 'flex-end';
    } else {
        vAlignContentValue = vAlignContent;
    }
    return (
        <View
            {...rest}
            className={cx(props.className, styles.horizontal)}
            style={{ alignItems: vAlignContentValue, minHeight }}
        />
    );
};

export const Vertical: React.FC<VerticalLayoutProps> = React.forwardRef(
    (props, ref) => {
        const { hAlignContent, minHeight, ...rest } = fixProps(props);
        return (
            <View
                {...rest}
                className={cx(props.className, styles.vertical)}
                column
                style={{ alignItems: hAlignContent, minHeight }}
                ref={ref}
            />
        );
    },
);
