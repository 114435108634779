import { create } from '@thoughtspot/logger';
import { dispatchNonMemoEvent } from '/@utils/event-bridge/event-bridge';
import { EventType } from './embed-base.util';

const logger = create('browser-fetch-xhr-custom-override');

let token: string;

const setToken = (newToken: string) => {
    token = newToken;
};

const addHeaderToFetch = (
    resource: RequestInfo | URL,
    options: RequestInit = {},
) => {
    let req;
    const LocalRequest = window.Request;
    const urlLink =
        resource instanceof Request ? resource.url : resource.toString();
    const urlToFetch = new URL(urlLink, window.origin);
    if (token && urlToFetch.origin === window.origin) {
        req = new LocalRequest(resource, {
            ...options,
            headers: {
                ...(options.headers || {}),
                Authorization: `Bearer ${token}`,
            },
        });
        logger.log('addHeaderToFetch req url with token set ', urlLink);
    } else {
        req = new LocalRequest(resource, options);
    }
    return req;
};

const ERROR_STATUS_CODES = [401, 500, 502];
let commonTokenPromise: Promise<unknown> | null = null;
const tokenApi = () => {
    if (!commonTokenPromise) {
        commonTokenPromise = dispatchNonMemoEvent(EventType.AuthExpire, {});
        commonTokenPromise
            .then(() => {
                commonTokenPromise = null;
                return null;
            })
            .catch(() => {
                commonTokenPromise = null;
            });
    }
    return commonTokenPromise;
};

const overrideBrowserFetchForToken = () => {
    const nativeFetch = window.fetch;
    const modifiedFetch: typeof window.fetch = async (
        input: RequestInfo | URL,
        init: RequestInit,
    ) => {
        const initialResponse = await nativeFetch(
            addHeaderToFetch(input, init),
        );

        const isNotTokenExpired = !ERROR_STATUS_CODES.includes(
            initialResponse.status,
        );

        if (isNotTokenExpired) return initialResponse;

        try {
            const tokenResponse = (await tokenApi()) as {
                data: { authToken: string };
            };

            if (!tokenResponse?.data?.authToken) return initialResponse;

            setToken(tokenResponse.data.authToken);
        } catch (err) {
            logger.error('Could not fetch new token ', err);
            return initialResponse;
        }

        // new token was successfully fetched, retry the request
        return nativeFetch(addHeaderToFetch(input, init));
    };
    window.fetch = modifiedFetch;
};

const overrideBrowserXHRForToken = () => {
    const nativeXhrOpen = window.XMLHttpRequest.prototype.open;
    (window.XMLHttpRequest as any).prototype.open = function addToken(
        method: string,
        url: string,
        async: boolean,
        user: string,
        password: string,
    ) {
        const urlLink = url.toString();
        const urlToFetch = new URL(urlLink, window.origin);
        nativeXhrOpen.call(this, method, url, async, user, password);
        if (token && urlToFetch.origin === window.origin) {
            this.setRequestHeader('Authorization', `Bearer ${token}`);
            logger.log(
                'current token in cookieless url request in overrideBrowserXHRForToken ',
                urlLink,
            );
        }
    };
};

const requestInterceptor = ({ options }: any) => {
    // eslint-disable-next-line no-param-reassign
    options.headers = {
        Authorization: `Bearer ${token}`,
    };
    return options;
};

const overrideBrowserFetchAndXhrForToken = (token: string) => {
    setToken(token);
    overrideBrowserFetchForToken();
    overrideBrowserXHRForToken();
};

export {
    overrideBrowserFetchForToken,
    overrideBrowserXHRForToken,
    overrideBrowserFetchAndXhrForToken,
    setToken,
    requestInterceptor,
};
