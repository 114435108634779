import React, { useCallback, useEffect, useRef } from 'react';
import { useAppContext } from '/@contexts/global-app-context';
import {
    getCustomUrl,
    hasCustomUrls,
} from '/@services/everywhere/everywhere-service.util';
import { linkOverrideEnabled } from '/@utils/embed.util';
import { isEmbeddedApp } from '/@utils/embed-base.util';

export const CustomOverrideLinkComponent: React.FC = () => {
    // suppress the openNew Link tab with custom link values
    if (!linkOverrideEnabled()) return null;

    // Maintain embed params in a state, so that it can be updated based on any of the embed events.
    const { sessionService } = useAppContext();
    const contextMenuHandler = useRef(null);

    // This method recursively find the parent element to be Anchor tag
    const parentByTag = useCallback((el: HTMLElement, tag: string):
        | HTMLAnchorElement
        | undefined => {
        if (!el || el.tagName === tag) {
            return el as HTMLAnchorElement;
        }
        return parentByTag(el.parentElement, tag);
    }, []);

    useEffect(() => {
        // refetch();
        const isEmbedded = isEmbeddedApp();
        const customLinkUrls = (sessionService as any).getCurrentOrgEmbedUrls();

        if (isEmbedded && hasCustomUrls(customLinkUrls)) {
            // dummy link which replaces the actual link on the page
            const link = document.createElement('a');
            link.style.position = 'fixed';
            link.style.width = '10px';
            link.style.height = '10px';
            link.style.zIndex = '1000';
            contextMenuHandler.current = (e: MouseEvent) => {
                const anchorElement: HTMLAnchorElement = parentByTag(
                    e.target as any,
                    'A',
                );
                if (anchorElement) {
                    if (anchorElement.tagName === 'A') {
                        // getCustomURL should pick the url and convert it into customURLs
                        // position it so it is under the cursor
                        link.href = getCustomUrl(
                            anchorElement.href,
                            customLinkUrls,
                        );
                        link.style.left = `${e.clientX - 5}px`;
                        link.style.top = `${e.clientY - 5}px`;
                        // add it to the dom
                        document.body.append(link);
                        // remove the link from the dom right after the contextmenu appears
                        setTimeout(() => link.remove(), 1);
                    }
                }
            };
            document.addEventListener(
                'contextmenu',
                contextMenuHandler.current,
            );
        }
        return () =>
            document.removeEventListener(
                'contextmenu',
                contextMenuHandler.current,
            );
    }, []);

    return <></>;
};
