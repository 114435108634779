import { FontFace, FontFaceCSSRule } from '@thoughtspot/custom-style-service';
import { generateUUID } from '@thoughtspot/js-util';
import { assign } from '@thoughtspot/string-util';
import _ from 'lodash';

const CUSTOM_STYLE_NODE_ID = `custom-style-${generateUUID()}`;

const getCustomStyleNode = (): HTMLStyleElement => {
    let styleNode: Element = document.querySelector(`#${CUSTOM_STYLE_NODE_ID}`);

    if (!styleNode) {
        styleNode = document.createElement('style');
        styleNode.setAttribute('id', CUSTOM_STYLE_NODE_ID);
        styleNode.setAttribute('type', 'text/css');
        document.head.appendChild(styleNode);
    }
    return styleNode as HTMLStyleElement;
};

const addCustomFontToSheet = (font: FontFace) => {
    const styleNode: HTMLStyleElement = getCustomStyleNode();
    const styleSheet: CSSStyleSheet = styleNode.sheet as CSSStyleSheet;
    const fontFaceRule: FontFaceCSSRule = font.toFontFaceRule();

    const fontFaceRuleText: string = assign(
        `@font-face {
            font-family: {fontFamily};
            src: url('{fontUrl}') format('{fontFormat}');
        }`,
        fontFaceRule,
    );
    styleSheet.insertRule(fontFaceRuleText, styleSheet.cssRules.length);
};

export * from '@thoughtspot/custom-style-service';
