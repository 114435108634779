import { MutationTuple } from '@apollo/client';
import { useEffect, useState } from 'react';

// Define the types for the functions
export type GetStringFunction = () => string;

// Define the types for the functions
export type GetNumberFunction = () => number;

export type UseAddUserToModeMutationType = (
    onCompleted: (data: any) => void,
    onError: (error: any) => void,
    variables: any,
) => MutationTuple<any, any>;

export type UseGetAnalystStudioAccessInfoLazyQueryType = () => {
    getStudioAccessInfo: (variables: { orgId: string }) => void;
    analystStudioAccessData: any;
    loading: boolean;
    error: any;
};

export interface AddUserResponse {
    status: number;
}

// Default Identity service headers type.
export interface DefaultIdentityServiceHeaders {
    accessToken: string;
    xTsPrivileges: string;
    xTsIdpDomain: string;
}

// get default identity service headers.
export const getDefaultSamlHeaders = (
    getAccessToken: GetStringFunction,
    getIdpDomain: GetStringFunction,
    getUserPrivileges: GetStringFunction,
): DefaultIdentityServiceHeaders => {
    return {
        accessToken: `Bearer ${getAccessToken()}`,
        xTsPrivileges: btoa(JSON.stringify(getUserPrivileges())),
        xTsIdpDomain: getIdpDomain(),
    };
};

// use add user to analysts studio mutation.
export const useAnalystStudioMutation = (
    getAccessToken: GetStringFunction,
    getOktaUserId: GetStringFunction,
    getUserPrivileges: GetStringFunction,
    getCurrentOrgId: GetNumberFunction,
    useAddUserToModeMutation: UseAddUserToModeMutationType,
    useGetAnalystStudioAccessInfoLazyQuery: UseGetAnalystStudioAccessInfoLazyQueryType,
    getIdpDomain: GetStringFunction,
    getClientId: GetStringFunction,
) => {
    const [isRequestPending, setRequestPending] = useState<boolean>(false);

    // navigate to mode window.
    const doModeAuthenticatoionAndRedirect = (
        url: string,
        workspaceName: string,
        authToken: string,
    ) => {
        // Make a POST request with the Bearer token in the headers
        // TODO : find if in future we can shift to fetch.
        fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        })
            .then(response => {
                setRequestPending(false);

                // Manually handle redirection
                if (response.status === 204) {
                    const redirectUrl = response.headers.get('location');
                    if (redirectUrl) {
                        window.open(redirectUrl, '_blank');
                    } else {
                        const redirectUrl2 = response.headers.get('Location');
                        if (redirectUrl2) {
                            window.open(redirectUrl2, '_blank');
                        }
                    }
                }
                return response;
            })
            .catch(error => {
                setRequestPending(false);
                return error;
            });
    };

    // Lazy query for getting analyst studio access info.
    const {
        getStudioAccessInfo: getAccessInfo,
        analystStudioAccessData: accessData,
        loading: accessLoading,
        error: accessError,
    } = useGetAnalystStudioAccessInfoLazyQuery();

    // Define the second mutation.
    const [
        addUserToMode,
        {
            data: addUserResponse,
            loading: isAddUserLoading,
            error: addUserError,
        },
    ] = useAddUserToModeMutation(
        (response: any) => {
            // TODO @Deepak.Nandwani : Handle responses.
        },
        (errors: any) => {
            // TODO @Deepak.Nandwani : Handle errors.
        },
        {
            userId: getOktaUserId(),
            clientId: getClientId(),
            defaultHeaders: getDefaultSamlHeaders(
                getAccessToken,
                getIdpDomain,
                getUserPrivileges,
            ),
        },
    );

    useEffect(() => {
        if (!(accessLoading || isAddUserLoading)) {
            if (accessError || addUserError) {
                setRequestPending(false);
            } else if (addUserResponse && accessData) {
                const {
                    analystStudioUrl,
                    analystStudioWSId,
                    jwtToken,
                } = accessData;
                setRequestPending(false);
                doModeAuthenticatoionAndRedirect(
                    `https://${analystStudioUrl}`,
                    analystStudioWSId,
                    jwtToken,
                );
            }
        }
    }, [
        accessData,
        accessLoading,
        accessError,
        isAddUserLoading,
        addUserError,
        addUserResponse,
    ]);

    // use effect will stop subsequent requests till one is going on.
    useEffect(() => {
        if (isRequestPending) {
            // trigger all hooks, request arrived.
            addUserToMode();
            getAccessInfo({ orgId: `${getCurrentOrgId()}` });
        }
    }, [isRequestPending]);

    const onAnalystStudioClicked = () => {
        // make a request to trigger hooks.
        setRequestPending(true);
    };
    return { onAnalystStudioClicked, isRequestPending };
};
