import { ApolloError } from '@apollo/client';
import { create } from '@thoughtspot/logger';
import * as session from '@thoughtspot/session-service';
import { setToken } from '/@utils/browserOverrideForFetchAndXhr.util';
import { EventType } from '/@utils/embed.util';
import { dispatchNonMemoEvent } from '/@utils/event-bridge/event-bridge';

const logger = create('navigate-to-login-check.utils');

export const notifyOnAuthExpiration = () => {
    return dispatchNonMemoEvent(EventType.AuthExpire, {})
        .then(({ data }: any) => {
            if (data.authToken) {
                setToken(data.authToken);
            }
            return null;
        })
        .catch(error => {
            logger.error(error);
        });
};

export const getStatusCodeFromPrismError = (apolloError: ApolloError) => {
    const networkError: any = apolloError.networkError;

    if (networkError?.result?.errors) {
        const error = networkError.result.errors[0];
        const upstreamResponse = error?.extensions?.exception?.upstreamResponse;
        const status = upstreamResponse?.status;

        if (status) {
            return status;
        }
        return networkError.statusCode;
    }
    // if response does not return in JSON.
    if (networkError?.statusCode) return networkError.statusCode;

    return null;
};

export const isCurrentlyLoggedIn = !!session.getSessionInfo();
