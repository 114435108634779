import { parsePrivilegedRouteMap } from '@thoughtspot/blink-context';
import { AppLogoType } from '@thoughtspot/custom-style-service';
import { Vertical } from '@thoughtspot/radiant-react/widgets/layout';
import React, { useEffect } from 'react';
import { createHashRouter, Outlet, RouterProvider } from 'react-router-dom';
import { useFavicon } from 'react-use';
import { FLAGS, flags } from '/@services/system/config-service/flags-service';
import {
    getUrlWithoutUTMParameters,
    replaceState,
    useSetupNavService,
} from '/@services/system/nav-service/nav-service';
import { useAppContext } from './contexts/global-app-context';
import { AuthenticatedRoutes } from './routing/authenticated-app-view';
import { UNAUTHENTICATED_ROUTE_OBJECTS } from './routing/core-routes';
import { NavActions } from './routing/nav-actions';
import { collectQueryParamEvent } from './services/metrics-service/metrics-service';
import { BlinkV2MetricsContextStates } from './services/system/blink-v2-metrics-context-states';
import { ErrorBoundary } from './utils/error-boundary/error-boundary';

declare const window: any;
const KPI_MONITOR = 'kpi_monitor';

const AppRoot: React.FC = () => {
    const { customStylingService } = useAppContext();
    useFavicon(
        customStylingService.getConfig()?.getFaviconUrl(AppLogoType.DEFAULT),
    );
    const setup = useSetupNavService();
    useEffect(() => setup(), []);
    useEffect(() => {
        // Logging mfeinfo to include in har file
        if (window.blink?.microfrontends) {
            fetch('/prism/mfeInfo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(window.blink?.microfrontends),
            });
        }
    }, []);

    /**
     * Removes UTM source and UTM medium parameters from the URL if the UTM source is 'kpi_monitor'.
     * This ensures accurate mixpanel tracking of users who click the 'View KPI' button from the email.
     */
    const UTMSource = flags.getValue(FLAGS.utm_source.name);
    useEffect(() => {
        if (UTMSource === KPI_MONITOR) {
            const updatedURL = getUrlWithoutUTMParameters();
            replaceState(updatedURL);
        }
    }, [UTMSource]);

    return (
        <Vertical grow vAlignContent="center">
            <NavActions />
            <Outlet />
        </Vertical>
    );
};

export const getMainRouteObject = () =>
    parsePrivilegedRouteMap({
        Root: {
            element: <AppRoot />,
            children: {
                AuthenticatedRoutes,
                ...UNAUTHENTICATED_ROUTE_OBJECTS,
            },
            loader: async (options: any): Promise<void> => {
                const { apolloCachePolicyLoader } = await import(
                    './apollo-client-policies'
                );
                await apolloCachePolicyLoader();
                collectQueryParamEvent(options.request.url);
                return null;
            },
            shouldRevalidate: ({
                nextUrl,
                currentUrl,
            }: {
                nextUrl: URL;
                currentUrl: URL;
            }) => nextUrl.search !== currentUrl.search,
            errorElement: (
                <Vertical minHeight="100%" vAlignContent="center">
                    <ErrorBoundary />,
                </Vertical>
            ),
            pageContext: BlinkV2MetricsContextStates.DEFAULT_PAGE,
        },
    });

export const App: React.FC = () => {
    const hashRouter = createHashRouter(getMainRouteObject());
    return <RouterProvider router={hashRouter} />;
};
