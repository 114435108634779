import { useTranslation } from '@thoughtspot/i18n';
import React from 'react';
import { Horizontal } from '../../utils/layouts';
import styles from './footer.module.scss';
import { FooterProps } from './interfaces';

export const Footer: React.FC<FooterProps> = ({ footerText }: FooterProps) => {
    const { t } = useTranslation();

    return (
        <Horizontal className={styles.footerContainer}>
            <span className={styles.footerText}>{footerText}</span>
            <img
                className={styles.footerLogo}
                src="/resources/img/logo/TS-PoweredBy-Black.svg"
                alt={t('EmbedDev.Home.BannerHeadII')}
            />
        </Horizontal>
    );
};
