import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

enum Actions {
    SET_ANSWER_SESSION,
    SET_WORKSHEET_SESSION,
    SET_UNAGGREGATED_ANSWER_SESSION,
}

interface ClientState {
    answerSessionId: string;
    worksheetSessionId: string;
}

interface Action {
    type: Actions;
    [key: string]: any;
}

const { Provider, useTracked } = createContainer(() =>
    useReducer(
        (state: ClientState, action: Action) => {
            switch (action.type) {
                case Actions.SET_ANSWER_SESSION:
                    return {
                        ...state,
                        answerSessionId: action.sessionId,
                    };
                case Actions.SET_WORKSHEET_SESSION:
                    return {
                        ...state,
                        worksheetSessionId: action.sessionId,
                    };
                case Actions.SET_UNAGGREGATED_ANSWER_SESSION:
                    return {
                        ...state,
                        unAggregatedAnswerSessionId: action.sessionId,
                    };
                default:
                    return state;
            }
        },
        {
            answerSessionId: '',
            worksheetSessionId: '',
            unAggregatedAnswerSessionId: '',
        },
    ),
);

export const useGlobalClientState = (): any => {
    const [state, dispatch] = useTracked();
    const setAnswerSessionId = (sessionId: string) => {
        dispatch({ type: Actions.SET_ANSWER_SESSION, sessionId });
    };
    const setWorksheetSessionId = (sessionId: string) => {
        dispatch({ type: Actions.SET_WORKSHEET_SESSION, sessionId });
    };
    const setUnAggregatedAnswerSessionId = (sessionId: string) => {
        dispatch({ type: Actions.SET_UNAGGREGATED_ANSWER_SESSION, sessionId });
    };
    return [
        state,
        {
            setAnswerSessionId,
            setWorksheetSessionId,
            setUnAggregatedAnswerSessionId,
        },
    ];
};

export const ClientStateContextProvider = Provider;
