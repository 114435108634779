/**
 * Utilities for Error handling and propagation
 */

import { ApolloError, DocumentNode, TypedDocumentNode } from '@apollo/client';
import {
    blinkConstants,
    CONNECTOR_DISPLAY_NAME,
} from '@thoughtspot/blink-constants';
import { ErrorObject, IDefaultErrorCodes } from '@thoughtspot/blink-context';
import { translate } from '@thoughtspot/i18n';
import { ButtonType } from '@thoughtspot/radiant-react/widgets/button/button';
import { GraphQLError } from 'graphql';
import _ from 'lodash';
import { useEffect, useState } from 'react';

export interface EmbraceAuthConfig {
    alertTitle: string;
    alertDesc: string;
    primaryLink?: {
        label: string;
        type: ButtonType;
        onClick?: () => void;
        className?: string;
    };
}

export enum ErrorSize {
    small = 'small',
    medium = 'medium',
    large = 'large',
}

const SMALL_ERROR_SIZE_BREAKPOINT = 2;
const MEDIUM_ERROR_SIZE_BREAKPOINT = 3;

export interface ErrorConfig {
    canShowTitle: boolean;
    canShowDesc: boolean;
    canShowLink: boolean;
    canShowIcon: boolean;
}

/**
 * small: icon
 * medium: title + link+ icon
 * large: everything
 *
 */
export const VizErorSizeConfig: {
    [key in ErrorSize]?: ErrorConfig;
} = {
    // show icon only
    [ErrorSize.small]: {
        canShowTitle: false,
        canShowDesc: false,
        canShowLink: false,
        canShowIcon: true,
    },
    // show icon + link + title
    [ErrorSize.medium]: {
        canShowTitle: true,
        canShowDesc: false,
        canShowLink: true,
        canShowIcon: true,
    },
    // show icon + link + title + desc
    [ErrorSize.large]: {
        canShowTitle: true,
        canShowDesc: true,
        canShowLink: true,
        canShowIcon: true,
    },
};

/**
 * Utility function to convert graphql errors to ErrorObject
 * @param graphqlError .
 */
function getErrorObjectFromGraphQLErrors<T>(
    graphqlError: ApolloError,
    query?: any,
    errorMappingContext?: Record<string, any>,
): ErrorObject<T>[] | undefined {
    // For network only errors (eg. internet connectivity issues, server not
    // reachable etc) the graphqlError.networkError contains an error
    // obj directly
    const nwError = graphqlError?.networkError;
    if (
        !(nwError as any)?.result?.errors &&
        _.isEmpty(graphqlError?.graphQLErrors)
    ) {
        return [
            {
                queryName: query?.definitions
                    ?.map((def: any) => (def as any)?.name?.value || '')
                    .join(','),
                code: 'NW_ERROR',
                message: '',
                errorObj: nwError,
                errorMappingContext,
            },
        ];
    }

    // The result object is not on the apollo error type, hence we
    // typecast to any
    const errs: GraphQLError[] =
        (graphqlError?.networkError as any)?.result?.errors ||
        graphqlError?.graphQLErrors;
    if (!errs) return undefined;

    const errors: ErrorObject[] = errs.map(e => {
        return {
            queryName: query?.definitions
                ?.map((def: any) => (def as any)?.name?.value || '')
                .join(','),
            code: e?.extensions?.code || 'ERROR_UNKNOWN_ERROR',
            message: e?.message,
            errorObj: e,
            errorMappingContext,
        };
    });

    return errors;
}

/**
 * Utility function to get errorCode from graphql errors
 * @param graphqlError .
 */
function getErrorCodeFromGraphQLErrors<T>(graphqlError: ApolloError) {
    const errorObj = getErrorObjectFromGraphQLErrors(graphqlError);
    const errorCode =
        errorObj[0]?.errorObj?.extensions?.exception?.upstreamResponse?.data
            ?.code;
    return errorCode;
}
/**
 * Utility fn to check if any one of error codes exists in an
 * array of Errors
 * @param errorList - List of errors on which search is performed
 * @param errorCodes - List of error codes to search for
 */
const containsError = <T>(
    errorList?: ErrorObject<T>[],
    errorCodes?: (T | IDefaultErrorCodes)[],
): boolean => {
    if (!(errorList?.length && errorCodes)) return false;
    if (errorCodes.indexOf('*') !== -1) {
        return true;
    }
    if (errorList.some(e => errorCodes.includes(e.code))) {
        return true;
    }
    return false;
};

/**
 * Utilityy fn to check if any one of error codes exists in ApolloError obj
 * @param error - Apollo Error
 * @param errorCodes - List of error codes to search for
 */
const containsInApolloError = <T>(
    error?: ApolloError,
    errorCodes?: (T | IDefaultErrorCodes)[],
): boolean => {
    if (!(error && errorCodes)) return false;
    const errorList = getErrorObjectFromGraphQLErrors(error);
    return containsError(errorList, errorCodes);
};

/**
 * Utility hook to automatically call the resetError callback when
 * a state variable updates.
 * This can be used to automaically clear errors when user has made a
 * corrective action either through an API call or through some other action
 * which would update a state variable.
 * For eg. in answer page if an API error occurs, we would want to automatically
 * clear that error whenever another API call is made and the answer object is updated
 *
 * @param errors - ErrorObject list obtained from ErrorBoundary or hook
 * @param resetErrors - ResetError callback
 * @param state - A state variable which when updated we should call resetErrors
 */
const useResetErrorOnStateChange = (
    errors: ErrorObject[],
    resetErrors: () => void,
    state: any,
) => {
    const [prevState, setPrevState] = useState<any>(undefined);
    useEffect(() => {
        if (errors.length !== 0) {
            if (prevState === undefined) {
                // If there is an error (errors array is not empty)
                // Then we use update the prevState variable to hold
                // the current value of the state variable, so that later
                // we can check if the state variable has changes
                setPrevState(state);
            } else if (prevState !== state) {
                // The state variable has changed from the value it had after
                // we got the error, now we can call th resetErrors
                resetErrors();
                setPrevState(undefined);
            }
        }
    }, [prevState, state]);
};

/**
 * Return true if we should use a warning (yellow) banner for the error
 */
const shouldUseWarningBanner = (err: ErrorObject) => {
    const ERROR_CODES_TO_USE_WARNING_BANNER = [
        blinkConstants.blinkGeneratedErrors.SAGE_INDEXING,
    ];
    return ERROR_CODES_TO_USE_WARNING_BANNER.includes(err?.code);
};

const getMaxLinesForDescription = (tileHeight: number) => {
    if (tileHeight <= 2) return 2;
    if (tileHeight <= 4) return 6;
    return undefined;
};

const getErrorSizeForViz = (height: number, width: number) => {
    if (_.isUndefined(height) || _.isUndefined(width)) {
        return ErrorSize.large;
    }
    if (
        height <= SMALL_ERROR_SIZE_BREAKPOINT ||
        width <= SMALL_ERROR_SIZE_BREAKPOINT
    )
        return ErrorSize.small;
    if (
        width === MEDIUM_ERROR_SIZE_BREAKPOINT ||
        height === MEDIUM_ERROR_SIZE_BREAKPOINT
    )
        return ErrorSize.medium;
    return ErrorSize.large;
};

/**
 * Utilityy fn to check if any one of error codes exists in Upstream Error
 * @param error - Apollo Error
 * @param errorCodes - List of error codes to search for
 */
const containsInUpstreamError = <T>(
    error?: ApolloError,
    errorCodes?: (T | IDefaultErrorCodes)[],
): boolean => {
    if (!(error && errorCodes)) return false;
    const errorList = getErrorObjectFromGraphQLErrors(error);
    const upstreamErrorCodes = _.reduce(
        errorList,
        (errors, err) => {
            if (err.code !== 'UPSTREAM_FAILURE') {
                return errors;
            }
            errors.push(
                `${err?.errorObj?.extensions?.exception?.upstreamResponse?.data?.code}`,
            );
            return errors;
        },
        [],
    );
    if (upstreamErrorCodes.length <= 0) {
        return false;
    }
    return _.some(upstreamErrorCodes, errCode => errorCodes.includes(errCode));
};

/**
 This function will return the object from an array of type{1: arr[0], 2: arr[1],.....}
 If there is the name of some connector in parameters it will return it's display name.
 * @param parameters from API's
 * @return
 */
const generateErrorParamMap = (parameters: string[] = []) => {
    const errorParamMap = {};
    parameters.forEach((param: string, index: number) => {
        errorParamMap[index + 1] = CONNECTOR_DISPLAY_NAME[param]
            ? CONNECTOR_DISPLAY_NAME[param]
            : param;
    });
    return errorParamMap;
};

/**
 *
 *  @param connectionName
 *  @param  authenticationByUserFailed
 *  @param  showLink
 *  @param  onClickHandler
 *  @param  primaryLinkClassName
 *  @param  buttonType
 * @returns
 */
const getEmbraceErrorConfig = ({
    connectionName,
    showLink = true,
    onClickHandler,
    authenticationByUserFailed,
    primaryLinkClassName,
    buttonType,
}: {
    connectionName: string;
    authenticationByUserFailed: boolean;
    buttonType: ButtonType;
    showLink?: boolean;
    onClickHandler?: () => void;
    primaryLinkClassName?: string;
}): EmbraceAuthConfig => {
    type ConnectorDisplayNameKey = keyof typeof CONNECTOR_DISPLAY_NAME;
    const connectorDisplayName =
        CONNECTOR_DISPLAY_NAME[connectionName as ConnectorDisplayNameKey] ||
        translate('embrace.default.datasourceDisplayName');

    const alertTitle = translate('embrace.oauthMutedAlert.heading');
    const primaryLink = showLink
        ? {
              label: translate('embrace.oauthMutedAlert.cta'),
              onClick: onClickHandler,
              type: buttonType,
              className: primaryLinkClassName,
          }
        : null;
    const alertDesc = authenticationByUserFailed
        ? translate('embrace.connection.oauth.autheticationFailed')
        : translate('embrace.oauthMutedAlert.body', {
              datasourceName: connectorDisplayName,
          });
    return { alertTitle, alertDesc, primaryLink };
};

export {
    containsError,
    containsInApolloError,
    getErrorObjectFromGraphQLErrors,
    useResetErrorOnStateChange,
    getErrorSizeForViz,
    getMaxLinesForDescription,
    shouldUseWarningBanner,
    containsInUpstreamError,
    getErrorCodeFromGraphQLErrors,
    getEmbraceErrorConfig,
    generateErrorParamMap,
};
