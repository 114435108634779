/**
 * Error boundary component for Blink
 */

import React from 'react';
import { ErrorBoundary, Props as ErrorBoundaryProps } from './error-boundary';

/**
 * Blink Generated and Blink Specific Error Codes
 */
const BlinkErrorCodes = {
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    UPSTREAM_ERROR: 'UPSTREAM_ERROR',
    GRAPHQL_VALIDATION_ERROR: 'GRAPHQL_VALIDATION_ERROR',
    NO_ERROR: 'NO_ERROR',
};

type IBlinkErrorCodes = keyof typeof BlinkErrorCodes;

export type BlinkErrorCode = IBlinkErrorCodes | string;

/**
 * Blink Error Boundary Component
 * Just a wrapper for the ErrorBoundary component with some types
 */
const BlinkErrorBoundary = (props: ErrorBoundaryProps<BlinkErrorCode>) => (
    <ErrorBoundary {...props} />
);

export { BlinkErrorBoundary, BlinkErrorCodes };
