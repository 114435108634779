import { envFlags, FLAGS } from '@thoughtspot/env-flags';

const nativeOpenKey = '_nativeOpen';

/**
 * Browser override for window open,
 * if disableRedirectionLinksInNewTab is true then it will open the link in the same iframe.
 */
export const overrideBrowserWindowOpen = () => {
    const nativeOpen = window.open;

    const customOpen: typeof window.open = (
        url?: string | URL,
        target?: string,
        features?: string,
    ) => {
        const areLinksDisabled = envFlags.getValue(
            FLAGS.disableRedirectionLinksInNewTab.name,
        );
        const updatedTarget = areLinksDisabled ? '_self' : target;
        return nativeOpen(url, updatedTarget, features);
    };

    window.open = customOpen;
    window[nativeOpenKey] = nativeOpen;
};
