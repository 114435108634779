/**
 * Following exports the hooks for analyst studio mutations.
 */
import {
    Get_Analyst_Studio_Info,
    Mode_AddUserPayload,
    useAddUserToModeMutation as _useAddUserToModeMutation,
    useGetAnalystStudioAccessInfoLazyQuery as _useGetAnalystStudioAccessInfoQuery,
} from '../generated/graphql-types';

export const useAddUserToModeMutation = (
    onComplete: (data: any) => void,
    onError: (error: any) => void,
    data: Mode_AddUserPayload,
) =>
    _useAddUserToModeMutation({
        variables: {
            data,
        },
        update: (cache, result) => {
            onComplete(result);
        },
        onError: (error: any) => {
            onError(error);
        },
    });

export const useGetAnalystStudioAccessInfoLazyQuery = () => {
    const [
        _getAccessInfo,
        { data, error, loading },
    ] = _useGetAnalystStudioAccessInfoQuery({
        fetchPolicy: 'network-only',
    });

    const analystStudioAccessData = data?.AnalystStudio__GetAccessInfo?.Data;

    const getStudioAccessInfo = (idData: Get_Analyst_Studio_Info) => {
        return _getAccessInfo({
            variables: {
                data: idData,
            },
        });
    };

    return {
        getStudioAccessInfo,
        error,
        loading,
        analystStudioAccessData,
    };
};
