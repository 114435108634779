/*
Liveboard ChartViz Caching Service

This Service is responsible for maintaining Chart viz's cache for each genNos of the answers that is fetched in pinboard.
This helps in reducing the memory used by apollo watchers which otherwise will lead to jankiness in the UI with large number of charts.
*/

import { create } from '@thoughtspot/logger';
import _ from 'lodash';
import { ChartViz } from '../services/generated/graphql-types';

const logger = create('lb-chartViz-caching-service');

/*
structure: {
    [answerSessionId]: {chartViz, genNo, isLiveboardSession}
}
*/
let chartVizCache = {};

// read chartViz from local cache stored during liveboard
// loadContextBook call which is mapped with answerSessionId
const readFromLBChartVizCache = (answerSessionId: string) => {
    if (!answerSessionId) return null;
    const chartVizObj = _.get(chartVizCache, [answerSessionId], null);
    if (_.isNil(chartVizObj)) {
        logger.log(
            'Chart viz Cache Miss! Chart viz not found for answerSessionId:',
            answerSessionId,
        );
        return null;
    }
    if (!chartVizObj.isLiveboardSession) {
        logger.log(
            'Liveboard ChartViz Cache is built only for liveboard sessions. Try to read chartViz for answerId: vizId: answerSessionId',
            answerSessionId,
        );
        return null;
    }
    return chartVizObj;
};

const writeToLBChartVizCache = (
    answerSessionId: string,
    chartViz: ChartViz,
    genNo: number,
    isLiveboardSession: boolean,
) => {
    if (_.isNil(answerSessionId)) {
        logger.log('Undefined values for answerSessionId', answerSessionId);
        return;
    }
    logger.log(
        'Add/update Liveboard Chart viz cache for answerId: answerSessionId: vizId: answerSessionId',
        answerSessionId,
    );
    _.set(chartVizCache, [answerSessionId], {
        chartViz,
        genNo,
        isLiveboardSession,
    });
};

const evictLBChartVizCache = (answerSessionId: string) => {
    logger.log(
        'Evicted Liveboard Chart viz cache for answer Session Id',
        answerSessionId,
    );
    delete chartVizCache[answerSessionId];
};

const clearLBChartVizCache = () => {
    chartVizCache = {};
};

export {
    clearLBChartVizCache,
    evictLBChartVizCache,
    readFromLBChartVizCache,
    writeToLBChartVizCache,
};
