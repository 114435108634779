import { useMutation } from '/@utils/error-handling/apollo-hooks-wrappers/custom-apollo-hooks';
import {
    UpdateSeenStateDocument,
    UpdateSeenStateMutation,
    UpdateSeenStateMutationVariables,
    UpdateUserPersonaDocument,
    UpdateUserPersonaMutation,
    UpdateUserPersonaMutationVariables,
    UpdateUserPreferencesDocument,
    UpdateUserPreferencesMutation,
    UpdateUserPreferencesMutationVariables,
} from '../generated/graphql-types';

export const useUpdateSeenStateMutation = () => {
    const [updateSeenState, { data, error, loading }] = useMutation<
        UpdateSeenStateMutation,
        UpdateSeenStateMutationVariables
    >(UpdateSeenStateDocument);

    return {
        data: data?.User__updateSeenState,
        error,
        loading,
        updateSeenState: (props: {
            hasSeenOnboarding?: boolean;
            hasSeenWorksheetTour?: boolean;
            hasSeenAutoAnswerTour?: boolean;
        }) =>
            updateSeenState({
                variables: {
                    hasSeenOnboarding: props.hasSeenOnboarding,
                    hasSeenWorksheetTour: props.hasSeenWorksheetTour,
                    hasSeenAutoAnswerTour: props.hasSeenAutoAnswerTour,
                },
            }),
    };
};

export const useUpdateUserPreferences = () => {
    const [updateUserPreferences, { data, error, loading }] = useMutation<
        UpdateUserPreferencesMutation,
        UpdateUserPreferencesMutationVariables
    >(UpdateUserPreferencesDocument);

    return {
        data: data?.User__updateUserPreferences,
        error,
        loading,
        updateUserPreferences: (userId: string, userPreferences: string) =>
            updateUserPreferences({
                variables: {
                    userId,
                    userPreferences,
                },
            }),
    };
};

export const useUpdateUserPersona = () => {
    const [updateUserPersona, { data, error, loading }] = useMutation<
        UpdateUserPersonaMutation,
        UpdateUserPersonaMutationVariables
    >(UpdateUserPersonaDocument);

    return {
        data: data?.User__updateUserPersona,
        error,
        loading,
        updateUserPersona: (userpersona: string) =>
            updateUserPersona({
                variables: {
                    userpersona,
                },
            }),
    };
};
