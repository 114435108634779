import { useTranslation } from '@thoughtspot/i18n';
import { Button } from '@thoughtspot/radiant-react/widgets/button';
import { IconID } from '@thoughtspot/radiant-react/widgets/icon';
import { Modal, ModalSize } from '@thoughtspot/radiant-react/widgets/modal';
import {
    Colors,
    Typography,
} from '@thoughtspot/radiant-react/widgets/typography';
import cx from 'classnames';
import _ from 'lodash';
import React, { FC } from 'react';
import styles from './stepper-progress-bar.module.scss';

export enum StepStatus {
    pending = 'pending',
    inProgress = 'inProgress',
    done = 'done',
}

export interface StepperProgressSteps {
    name: string;
    status: StepStatus;
}

export interface StepperProgressBarProps {
    /**
     * Start starting message
     */
    startMessage?: string;

    /**
     * Progress steps
     */
    steps: StepperProgressSteps[];

    /**
     * Start button test
     */
    startBtnText: string;

    /**
     * Start button callback
     */
    startBtnCb: () => void;

    /**
     * Finish button test
     */
    finishBtnText: string;

    /**
     * Finish button callback
     */
    finishBtnCb: () => void;

    /**
     * CB on dismissBtn click
     */
    onDismissBtnClick: () => void;

    /**
     * ProgressBar container class name
     * @default ''
     */
    className?: string;
}

export const StepperProgressBar: FC<StepperProgressBarProps> = ({
    startMessage,
    steps = [],
    startBtnText,
    startBtnCb,
    finishBtnText,
    finishBtnCb,
    onDismissBtnClick,
    className = '',
    ...restProps
}: StepperProgressBarProps) => {
    const showInProgressState = _.some(
        steps,
        step => step.status === StepStatus.done,
    );

    const [showModal, setModal] = React.useState(false);
    const [showProgressBar, setProgressBar] = React.useState(true);
    const { t } = useTranslation();

    if (!showProgressBar) {
        return null;
    }

    return (
        <div
            className={cx(className, styles.progressBarWrapper)}
            {...restProps}
        >
            <div className={styles.progressStepsWrapper}>
                {!showInProgressState && (
                    <Typography variant="p" color={Colors.white} noMargin>
                        {startMessage}
                    </Typography>
                )}
                <div className={styles.stepsWrapper}>
                    {steps.map((step, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className={styles.steps} key={`step-${index}`}>
                            {showInProgressState && (
                                <Typography
                                    variant="p"
                                    color={Colors.white}
                                    noMargin
                                >
                                    {step.name}
                                </Typography>
                            )}
                            <div className={styles.progressBar}>
                                {step.status === StepStatus.inProgress && (
                                    <div
                                        className={styles.pendingBaseContent}
                                    />
                                )}
                                <div
                                    data-testid={`step-${index}`}
                                    className={cx(
                                        styles.progressBarInner,
                                        styles[step.status],
                                    )}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {showInProgressState ? (
                <Button
                    type="secondary"
                    backgroundType="colored"
                    text={finishBtnText}
                    onClick={() => finishBtnCb()}
                />
            ) : (
                <Button
                    type="primary"
                    text={startBtnText}
                    onClick={() => startBtnCb()}
                />
            )}
            <Button
                icon={IconID.CROSS}
                onClick={() => setModal(true)}
                backgroundType="colored"
                size="s"
                className={styles.crossBtn}
            />
            <Modal
                isOpen={showModal}
                size={ModalSize.SMALL}
                title={t('removeConfirmationText')}
                dismissButton={{
                    text: t('removeSetupProgressBarText'),
                    onClick: () => {
                        setModal(false);
                        setProgressBar(false);
                        onDismissBtnClick();
                    },
                }}
                confirmButton={{
                    text: t('Cancel'),
                    onConfirm: () => {
                        setModal(false);
                    },
                }}
            >
                <div>{t('removeSetupProgressBarMessage')}</div>
            </Modal>
        </div>
    );
};
