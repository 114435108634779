import { Microfrontend } from '@thoughtspot/microfrontends';
import React from 'react';
import { loadMicroFrontendComponent } from './microfrontend-wrapper';
import {
    getMicroFrontendComponent,
    initMicroFrontend,
} from './microfrontends.util';

enum GlobalNavComponents {
    TopNav = 'TopNav',
    OrgMenuContainer = 'OrgMenuContainer',
}

export const TopNav = loadMicroFrontendComponent(
    Microfrontend.GLOBAL_NAV,
    GlobalNavComponents.TopNav,
);

export const OrgMenuContainer = loadMicroFrontendComponent(
    Microfrontend.GLOBAL_NAV,
    GlobalNavComponents.OrgMenuContainer,
);

initMicroFrontend(Microfrontend.GLOBAL_NAV);
