import { create } from '@thoughtspot/logger';
import _ from 'lodash';
import {
    ApolloClient,
    DocumentNode,
} from '/@src/utils/error-handling/apollo-hooks-wrappers/custom-apollo-hooks';

type SessionId = {
    sessionId: string;
    genNo: number;
    acSession?: {
        sessionId: string;
        genNo: number;
    };
};

const logger = create('Stateful-Service-Hooks-Util');

/**
 * Session ID used in Jest tests (unit + integration)
 */
export const TEST_SESSION_ID: SessionId = {
    sessionId: 'TEST_SESSION_ID',
    genNo: 0,
};

export const removeTypeName = (obj: any) => {
    if (!obj) {
        return obj;
    }
    const { __typename, ...rest } = obj;
    return rest;
};

export const removeSessionTypename = (
    session: any,
    nestedSessionKeys: string[],
) => {
    nestedSessionKeys.forEach(key => {
        const nestedSessions = _.get(session, key);
        if (nestedSessions) {
            if (Array.isArray(nestedSessions)) {
                const updatedNestedSession: any = [];
                nestedSessions.forEach(nestedSession => {
                    const ns = removeTypeName(nestedSession);
                    const sessionId = removeTypeName(ns.sessionId);
                    const acSession = removeTypeName(sessionId.acSession);
                    ns.sessionId = sessionId;
                    sessionId.acSession = acSession;
                    updatedNestedSession.push(ns);
                });
                _.set(session, key, updatedNestedSession);
            } else {
                const nestedSession = removeTypeName(nestedSessions);
                _.set(session, key, nestedSession);
            }
        }
    });
    return session;
};

export function getSession(
    client: ApolloClient<object>,
    sessionId: string,
    typename: string,
    query: DocumentNode,
    // The nested keys are used to remove the __typename field from those objects.
    nestedSessionKeys: string[] = [],
): SessionId {
    const sessionObject = {
        __typename: typename,
        sessionId,
    };
    const sessionInfoFromCache: any = client.cache.readQuery({
        id: client.cache.identify(sessionObject),
        query,
    });
    // eslint-disable-next-line no-underscore-dangle
    if (sessionId && !sessionInfoFromCache?.__typename) {
        logger.error(
            'Could not find session object in cache for typename',
            typename,
            'and sessionId',
            sessionId,
        );
    }

    const { __typename, ...session } = sessionInfoFromCache || {};
    removeSessionTypename(session, nestedSessionKeys);
    return (session as SessionId) || TEST_SESSION_ID;
}
