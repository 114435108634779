import {
    initLocalStore,
    initWebStorage,
    WebStorage,
} from '@thoughtspot/local-store';

export function startWebStorage() {
    const localStore = new WebStorage('localStorage');
    initLocalStore(localStore as any);
    return initWebStorage();
}

export { localStore, sessionStore } from '@thoughtspot/local-store';
