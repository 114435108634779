import { blinkConstants } from '@thoughtspot/blink-constants';
import React from 'react';
import { useAppContext } from '/@contexts/global-app-context';
import { EurekaMainSearchBar } from '../../microfrontends/eureka';

interface NavSearchBarContainerProps {
    eurekaSearchBarOnFocus?: () => void;
}

export const NavSearchBarContainer: React.FC<NavSearchBarContainerProps> = ({
    eurekaSearchBarOnFocus,
}) => {
    const { navService, sessionService } = useAppContext();

    const shouldFocusOnInit = () => {
        const shouldFocus =
            localStorage.getItem(blinkConstants.eureka.focusOnInit) ===
            true.toString();
        localStorage.setItem(
            blinkConstants.eureka.focusOnInit,
            false.toString(),
        );
        return shouldFocus;
    };

    return (
        <EurekaMainSearchBar
            getChooseDataSourcesComponent={null}
            focusOnInit={shouldFocusOnInit()}
            sessionService={sessionService}
            onFocusChanged={eurekaSearchBarOnFocus}
            isNavbarMode
            isGlobalNavEnabled
            isHomepage={false}
            goToEurekaWithQueryParams={navService.goToEurekaWithQueryParams}
        />
    );
};
