import { IconID } from '@thoughtspot/radiant-react/widgets/icon';
import { LoadingIndicator } from '@thoughtspot/radiant-react/widgets/loading-indicator';
import React, { Suspense } from 'react';
import { SessionService } from '/@contexts/global-app-context';
import { TopNav } from '../../microfrontends/global-nav';
import { UserMenuContainerWithInvite } from '../user-menu/user-menu.container';
import { NavSearchBarContainer } from './nav-search-bar.container';

// TODO(Sreeni): Figure out type passing from MFEs
export type NavLinkURI = {
    uri: string;
    linkTarget?: '_blank' | '_self';
    routerURI: never;
    callback: never;
    isReactShellSupported: boolean;
};

export type NavLinkCallback = {
    callback: () => void;
    routerURI?: string;
    uri: never;
    linkTarget: never;
    isReactShellSupported: never;
};

export declare type NavLinkWithTitleIconAndCallback = NavLinkCallback & {
    titleIconId: IconID;
};

export type NavLinkConfig = {
    name: string;
    id?: string;
    icon?: IconID;
    description?: string;
    target: NavLinkURI | NavLinkCallback | NavLinkWithTitleIconAndCallback;
};

interface GlobalNavProps {
    sessionService?: SessionService;
    homeButtonConfig: NavLinkConfig;
    renderSearchBar?: boolean;
    applicationSwitcherConfig?: NavLinkConfig[];
    searchBar?: React.ReactChild;
    userProfile?: React.ReactChild;
    activeApplicationID?: string;
    triggerV1Logout?: () => void;
    shouldShowPendoHelp: boolean;
    isCopilotEnabled?: boolean;
}
export const GlobalNav: React.FC<GlobalNavProps> = props => {
    return (
        <TopNav
            searchBar={
                !props.isCopilotEnabled &&
                props.renderSearchBar && (
                    <Suspense
                        fallback={
                            <LoadingIndicator.Global>
                                <></>
                            </LoadingIndicator.Global>
                        }
                    >
                        <NavSearchBarContainer />
                    </Suspense>
                )
            }
            userProfile={
                <UserMenuContainerWithInvite
                    onEmailAdd={null}
                    triggerV1Logout={props.triggerV1Logout}
                />
            }
            {...props}
        />
    );
};
export default GlobalNav;
