import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    from,
    InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getCurrentLocale } from '@thoughtspot/i18n';
import { generateUUID } from '@thoughtspot/js-util';
import { attachFullStoryCustomEventHandler } from '@thoughtspot/metrics';
import {
    getFormatLocales,
    getTseSchedulerClientId,
    isOrgPerUrlEnabled,
} from '@thoughtspot/session-service';
import _ from 'lodash';
import {
    FLAGS,
    flags as envFlags,
} from '/@services/system/config-service/flags-service';
import { isFullStoryEnabled } from '/@services/system/config-service/session-service';
import {
    getSubDomainIfOrgEnabled,
    getUrlHash,
} from '/@services/system/nav-service/nav-service';
import { eventErrorType, EventType } from '/@utils/embed-base.util';
import { dispatchNonMemoEvent } from '/@utils/event-bridge/event-bridge';
import { getValidComsToken } from './microfrontends/coms';
import { isRunningUnitTest } from './utils/testing/is-test';

enum ClientTypes {
    Blink,
    Mobile,
    FullEmbed,
    PublicApiDirect,
    BlinkV2,
}

const cache = new InMemoryCache({
    // Apollo uses Math.pow(2, 16) as a cache size for the computed outputs.
    // On a heavy liveboard, this fills up with just 5 vizzes, and any more loads keep re-writing the
    // cache and doesn't take any advantage of maintaining the cache.
    // Increase the limit to Math.pow(2, 20), but this can be adjusted later with more experiments.
    resultCacheMaxSize: 2 ** 20,
});

const requestIDAdder = new ApolloLink((operation, forward) => {
    let requestId = '';

    if (operation.variables?.networkRequestId) {
        requestId = operation.variables.networkRequestId;
    } else {
        requestId = generateUUID();
    }

    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'X-Request-Id': requestId,
            'X-Prism-Trace-Id': generateUUID(),
            'X-ThoughtSpot-Request-Context': getUrlHash(),
            'X-Callosum-Client-Type': ClientTypes.BlinkV2,
            'X-ThoughtSpot-Client-Id': getTseSchedulerClientId(),
            'X-Session-Check': operation?.operationName === 'isSessionActive',
        },
    }));
    return forward(operation);
});

const comsTokenAdder = setContext(
    async ({ operationName }, { headers = {} }) => {
        const comsToken = await getValidComsToken(operationName);
        return {
            headers: {
                ...headers,

                // X-Coms-Token is used as a temporary measure until IAMV2 is fully available.  See
                // https://thoughtspot.atlassian.net/wiki/spaces/COMS/pages/2619277542/COMS+with+IAMV2+Auth for more details.
                ...(comsToken && { 'X-Coms-Token': comsToken }),
            },
        };
    },
);

// This is the standard header to use in requests to indicate the preferred locale
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Language
const userLocaleAdder = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'Accept-Language': getCurrentLocale(),
        },
    }));
    return forward(operation);
});

const userDataFormatAdder = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'X-Date-Format-Locale': getFormatLocales().dateFormatLocale,
        },
    }));
    return forward(operation);
});

/* Include override headers */
const overridesAdder = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
        const overrideOrgId = envFlags.getValue(FLAGS.overrideOrgId.name);
        const overriddenHeaders = {};
        /* Checks primary(0)/non-primary org id */
        if (
            isOrgPerUrlEnabled() &&
            !getSubDomainIfOrgEnabled() &&
            overrideOrgId !== -1 &&
            (overrideOrgId === 0 || overrideOrgId)
        ) {
            overriddenHeaders['X-Thoughtspot-OrgsOverride'] = overrideOrgId;
        }
        return {
            headers: {
                ...headers,
                ...overriddenHeaders,
            },
        };
    });
    return forward(operation);
});

const errorIntercept = onError(errors => {
    if (isRunningUnitTest()) {
        return;
    }

    dispatchNonMemoEvent(EventType.Error, {
        errorType: eventErrorType.Api,
        error: errors.graphQLErrors,
    });
});

const customFetch = (uri: string, options: any) => {
    const { operationName } = JSON.parse(options.body);
    const promise = fetch(`${uri}?op=${operationName}`, options);

    // Track API metrics using FullStory custom event API
    if (isFullStoryEnabled()) {
        attachFullStoryCustomEventHandler(promise, operationName);
    }

    return promise;
};

const httpLink = createHttpLink({
    fetch: customFetch,
    credentials: 'same-origin',
    uri: '/prism/',
});

// Add X-Disable-Analytics for dev mode
const disableAnalytics = new ApolloLink((operation, forward) => {
    const ANALYTICS_DISABLED_APIS = [
        'CreateConversation',
        'LoadExistingAnswerSession',
        'GetChartWithData',
        'GetRecentlyPinnedPinboard',
        'UpdateConvContext',
        'SendMessage',
        'UpdateMessage',
        'GetTableWithData',
        'SendFeedback',
        'DeleteMessage',
        'UpdateClientStateTip',
        'GetConvAssistUnsupportedKeywords',
    ];
    const isE2ETest = envFlags.getValue('e2e');
    const isDev = process.env.NODE_ENV === 'development';
    if (
        ANALYTICS_DISABLED_APIS.includes(operation.operationName) &&
        (isDev || isE2ETest)
    )
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                'X-Disable-Analytics': 'true',
            },
        }));
    return forward(operation);
});

export const client = new ApolloClient({
    cache,
    link: from([
        disableAnalytics,
        requestIDAdder,
        comsTokenAdder,
        userLocaleAdder,
        userDataFormatAdder,
        overridesAdder,
        errorIntercept,
        httpLink,
    ]),
    resolvers: {},
});

export { NetworkStatus } from '@apollo/client';

// For debugging purposes
(window as any).client = client;

// For debugging purposes
(window as any).cache = cache;
