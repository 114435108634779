import { useEmbedContext } from '@thoughtspot/blink-context';
import { useTranslation } from '@thoughtspot/i18n';
import { create } from '@thoughtspot/logger';
import {
    collectEvent,
    FullStoryCustomEventName,
    FullStoryErrorPageEventProp,
    sendFullStoryCustomEvent,
    UserAction,
} from '@thoughtspot/metrics';
import { MutedAlert } from '@thoughtspot/radiant-react/widgets/alert/muted/muted-alert';
import React from 'react';
import {
    Action,
    getDisabledActionReason,
    getDisabledActions,
    getHiddenActions,
    getVisibleActions,
    shouldShowAction,
} from '/@utils/embed.util';
import { Vertical } from '../layouts';

interface ErrorBoundaryState {
    hasError: boolean;
}

const logger = create('error-boundary');
const ERROR_PAGE_SHOWN = 'ERROR_PAGE_SHOWN';

export const FallbackComponent = () => {
    const { t } = useTranslation();
    const embedConfig = useEmbedContext();
    const showPrimaryAction = embedConfig.isEmbedded
        ? shouldShowAction(
              getVisibleActions(embedConfig),
              getHiddenActions(embedConfig),
              Action.ReportError,
          )
        : true;
    const disablePrimaryAction = getDisabledActions(embedConfig).has(
        Action.ReportError,
    );
    const disabledActionReason = getDisabledActionReason(embedConfig);

    return (
        <Vertical hAlignContent="center" vAlignContent="center" grow>
            <MutedAlert
                global
                imageVisible
                title={t('answerInitError.title')}
                description={t('answerInitError.detail')}
                {...(showPrimaryAction && {
                    primaryAction: {
                        label: t('alertService.REPORT'),
                        onClick: () => {
                            window.open(
                                'https://community.thoughtspot.com/customers/s/topiccatalog',
                            );
                        },
                        isDisabled: disablePrimaryAction,
                        ...(disablePrimaryAction && {
                            tooltip: disabledActionReason,
                        }),
                    },
                })}
                secondaryAction={{
                    label: t('answerInitError.retry'),
                    onClick: () => {
                        window.location.reload();
                    },
                }}
            />
        </Vertical>
    );
};

// Have to use class component for this as there is no hook
// for componentDidCatch https://stackoverflow.com/a/59932120
// so for now doesn't seem possible to turn it into a functional component

export class ErrorBoundary extends React.Component<object, ErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // Send custom event to fullstory "ERROR_PAGE_SHOWN"
        sendFullStoryCustomEvent(FullStoryCustomEventName.ERROR_PAGE_SHOWN, {
            [FullStoryErrorPageEventProp.ERROR_MESSAGE]: error.message,
        });

        const eventMetrics: UserAction = {
            type: ERROR_PAGE_SHOWN,
            name: ERROR_PAGE_SHOWN,
            tags: [],
            messageParams: null,
            shouldSendEventToExperimentationService: false,
            experimentationProject: null,
        };
        collectEvent(eventMetrics);
        logger.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <FallbackComponent />;
        }

        return this.props.children;
    }
}
