import { useTranslation } from '@thoughtspot/i18n';
import { UserAction, userActionTypeEnum } from '@thoughtspot/metrics';
import { setIsAnalystOnboardingCompleted } from '@thoughtspot/session-service';
import React from 'react';
import { StepperProgressBar } from '../../radiant-candidate/stepper-progress-bar/stepper-progress-bar';
import { SetupProgressProps } from '../setup-hub-interface';

export enum StepperProgressDataTestId {
    STEPPER_PROGRESS = 'stepper-progress',
}

export const SetupProgress: React.FC<SetupProgressProps> = ({
    steps,
    goToSetup,
    propertyMap,
    collectEvent,
    updateUserPreferences,
    userGuid,
    getExposedUserPreferences,
}) => {
    const { t } = useTranslation();
    const onProgressBarBtnClick = () => {
        collectEvent(
            new UserAction(userActionTypeEnum.SETUP_HUB_ENTRY_BANNER),
            propertyMap,
        );
        goToSetup();
    };

    const onDismissBtnClick = () => {
        setIsAnalystOnboardingCompleted(true);
        updateUserPreferences(
            userGuid,
            JSON.stringify(getExposedUserPreferences()),
        );
    };
    return (
        <StepperProgressBar
            data-testid={StepperProgressDataTestId.STEPPER_PROGRESS}
            startMessage={t('setup.progressBar.start.text')}
            steps={steps}
            startBtnText={t('setup.progressBar.start.button')}
            startBtnCb={onProgressBarBtnClick}
            finishBtnText={t('setup.progressBar.finish.button')}
            finishBtnCb={onProgressBarBtnClick}
            onDismissBtnClick={onDismissBtnClick}
        />
    );
};
