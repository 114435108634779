import { getQueryParamsAndQueryString } from '@thoughtspot/env-flags';
import { create } from '@thoughtspot/logger';
import { Microfrontend, ReleaseJson } from '@thoughtspot/microfrontends';
import { loadCssDynamically } from '/@utils/browser.util';
import { MICRO_FRONTEND_DYNAMIC_IMPORT } from './microfrontends-config';

declare const window: any;

export type MfeResources = {
    mfeJsResourceUrl: string;
    mfeCssResourceUrl: string;
    mfeReleaseVersion: string;
    mfeBuildNumber: string;
};

const logger = create('microfrontends-util');
const initializedMicroFrontends = {};

export const getMfeResourceUrls = async (
    mfeName: Microfrontend,
): Promise<MfeResources> => {
    const { queryParams } = getQueryParamsAndQueryString();
    let mfeJsResourceUrl: string;
    let mfeCssResourceUrl: string;
    let mfeReleaseVersion: string;
    let mfeBuildNumber: string;
    if (queryParams[`${mfeName}Mfe`]) {
        // fetch and parse release json
        try {
            const response = await fetch(queryParams[`${mfeName}Mfe`]);
            const releaseJson: ReleaseJson = await response.json();
            mfeJsResourceUrl =
                releaseJson?.resources?.js &&
                [
                    releaseJson.basePath,
                    releaseJson.app,
                    releaseJson.resources.js,
                ].join('/');
            mfeCssResourceUrl =
                releaseJson?.resources?.css &&
                [
                    releaseJson.basePath,
                    releaseJson.app,
                    releaseJson.resources.css,
                ].join('/');
            mfeReleaseVersion = releaseJson.baseVersion;
            mfeBuildNumber = releaseJson.buildTag.split('-')[1];
        } catch (error) {
            logger.error(`Failed to fetch release json for ${mfeName}`);
        }
    } else if (queryParams[`${mfeName}_js`] && queryParams[`${mfeName}_css`]) {
        // To be used by MFE CI
        // Ensure MFE Folder names do not have an underscore
        mfeJsResourceUrl = queryParams[`${mfeName}_js`];
        mfeCssResourceUrl = queryParams[`${mfeName}_css`];
    } else if (window?.blink?.microfrontends?.[mfeName]?.js) {
        mfeJsResourceUrl = window?.blink?.microfrontends?.[mfeName]?.js;
        mfeCssResourceUrl = window?.blink?.microfrontends?.[mfeName]?.css;
        mfeReleaseVersion =
            window?.blink?.microfrontends?.[mfeName].baseVersion;
        mfeBuildNumber = window?.blink?.microfrontends?.[
            mfeName
        ].buildTag.split('-')[1];
    }
    return {
        mfeJsResourceUrl,
        mfeCssResourceUrl,
        mfeReleaseVersion,
        mfeBuildNumber,
    };
};

export const initMicroFrontend = async (mfeName: Microfrontend) => {
    if (!initializedMicroFrontends[mfeName]) {
        const {
            mfeJsResourceUrl,
            mfeCssResourceUrl,
        } = await getMfeResourceUrls(mfeName);
        if (mfeCssResourceUrl || mfeJsResourceUrl)
            logger.info(
                `Fetching JS and CSS resources from ${mfeJsResourceUrl} and ${mfeCssResourceUrl}`,
            );
        loadCssDynamically(mfeJsResourceUrl && mfeCssResourceUrl);
        initializedMicroFrontends[mfeName] = mfeJsResourceUrl
            ? // eslint-disable-next-line
              await import(/* @vite-ignore */ mfeJsResourceUrl)
            : await MICRO_FRONTEND_DYNAMIC_IMPORT[mfeName]();
        logger.info(`MFE ${mfeName} initialized`);
    }
};

export const getMicroFrontendHook = async (
    mfeName: Microfrontend,
    hookName: any,
) => {
    await initMicroFrontend(mfeName);
    const mfe = initializedMicroFrontends[mfeName];
    return mfe[hookName];
};

export const getMicroFrontendComponent = async (
    mfeName: Microfrontend,
    componentName: any,
) => {
    await initMicroFrontend(mfeName);
    const mfe = initializedMicroFrontends[mfeName];
    return {
        default: mfe[componentName],
    };
};
