import { AnswerColumn, AxisConfig, ChartViz } from '../generated/graphql-types';

export const updateAxisConfigColumns = (
    chartViz: ChartViz,
    overrides: Partial<AnswerColumn>,
) => {
    return chartViz.config.axisConfig.map((config: AxisConfig) => {
        const updatedConfig = { ...config };
        Object.keys(updatedConfig).forEach((key: string) => {
            if (Array.isArray(updatedConfig[key])) {
                updatedConfig[key] = updatedConfig[key].map(
                    (col: AnswerColumn) => {
                        if (col.id === overrides.id) {
                            return {
                                ...col,
                                ...overrides,
                            };
                        }
                        return col;
                    },
                );
            } else if (
                updatedConfig[key] &&
                updatedConfig[key].id === overrides.id
            ) {
                updatedConfig[key] = {
                    ...updatedConfig[key],
                    ...overrides,
                };
            }
        });
        return updatedConfig;
    });
};
