/**
 * NOTE: DONOT MAKE ANY CHANGES TO THIS FILE.
 * This file is a copy of the original file from the Blink.
 * and will be clean up / removed as part of implementing the onboarding MFE.
 */

import { NavService } from '@thoughtspot/blink-context';
import { SessionService } from '@thoughtspot/session-service';

export const canvasStates = {
    HOME: 'home',
    EMBEDV2: 'embed-v2',
    NON_EMBED: 'non-embed',
    NON_LOGGEDIN: 'non-loggedin',
    SEARCH_RESULTS: 'search-results',
    LOGIN: 'login',
    ACCOUNT_ACTIVATION: 'account-activation',
    REQUEST_RESET_PASSWORD: 'request-reset-password',
    RESET_PASSWORD: 'reset-password',
    EMBED: 'EMBED',
    PRINT: 'PRINT',
    PRINT_FOOLSCAP: 'PRINT_FOOLSCAP',
    ANSWERS: 'answers',
    EXPERT_REQUESTS: 'expert-requests',
    PINBOARDS: 'pinboards',
    INSIGHTS: 'insights',
    INSIGHT: 'insight',
    LEARN: 'learn',
    SAVED_ANSWER: 'saved-answer',
    REPLAY_ANSWER: 'replay-answer',
    ANSWER: 'answer',
    PINBOARD: 'pinboard',
    WORKSHEET: 'worksheet',
    VIEW: 'view',
    ADMIN: 'admin',
    SAAS_ADMIN: 'saas-admin',
    CLUSTER_STATUS: 'cluster-status',
    USER_PREFERENCE: 'user-preference',
    DATA: 'data',
    TABLE_DETAIL: 'table-detail',
    EMBED_TEST_PAGE: 'embed-test-page',
    EMBRACE_TABLE_DETAIL: 'embrace-table-detail',
    EMBRACE_REMAPPING: 'embrace-remapping',
    EMBRACE_CONNECTION_PAGE: 'embrace-connection-page',
    EMBRACE_CONNECTION_CONFIRMATION: 'embrace-connection-confirmation',
    IMPORT_CSV: 'importcsv',
    IMPORT_TSL: 'import-tsl',
    TSL_EDITOR: 'tsl-editor',
    SLACK_AUTH: 'slack-auth',
    IMPORT_DATA_SOURCE: 'importdatasource',
    CREATE_REPORT: 'schedule',
    REPORT_LIST: 'schedules',
    CREATE_SCHEMA: 'create-schema',
    SCHEMA_VIEWER: 'schema-viewer',
    SLACK_REGISTER: 'slack-register',
    SEEKWELL_AUTH_REDIRECT: 'seekwell-auth-redirect',
    VISUAL_TEST_PAGE: 'visual-test-page',
    SNAPSHOT: 'visualization-snapshot',
    ONBOARDING: 'onboarding',
    EUREKA: 'eureka',
    CREATE_AI_ANSWER: 'create-ai-answer',
    AI_ANSWER: 'ai-answer',
    CONV_ASSIST: 'conv-assist',
    EDGE: 'edge',
    NEWONBOARDING: 'new-onboarding',
    CAPTAIN: 'captain',
    TABLE_JOIN: 'table-join',
    SEARCH_ASSIST: 'search-assist',
    OAUTH: 'oauth',
    SETUP: 'setup',
    MONITOR_LIST: 'monitor-list',
    MONITOR_RULE_LIST: 'monitor-rule-list',
    MONITOR_DETAIL: 'monitor-detail',
    AUTO_WORKSHEET: 'auto-worksheet',
    AUTO_ANSWER: 'auto-answer',
    TEAMS: 'teams',
    ORGS: 'orgs',
    REQUEST_ACCESS: 'request-access',
    PURCHASE: 'purchase',
    DEMO: 'demo',
    COMS_CONNECT: 'coms-connect',
};

// All the possible urls in the app. For each url,
// a page type and state is set by the router mechanism
export const routePaths = {
    Home: '/',
    HomeAnswers: '/home/answers',
    HomeLiveboards: '/home/liveboards',
    HomeMonitorAlerts: '/home/monitor-alerts',
    HomeFavs: '/home/favourites',
    HomeCreatedByMe: '/home/created-by-me',
    HomeSpotIQAnalysis: '/home/spotiq-analysis',
    StratusHome: '/home',
    StratusHomeReact: '/insights/home',
    HomeSpotIQAnalysisReact: '/insights/home/spotiq-analysis',
    HomeCreatedByMeReact: '/insights/home/created-by-me',
    HomeFavsReact: '/insights//home/favourites',
    HomeMonitorAlertsReact: '/insights/home/monitor-alerts',
    HomeLiveboardsReact: '/insights/home/liveboards',
    HomeAnswersReact: '/insights/home/answers',
    EmbedV2: '/embed-v2',
    Admin: '/admin',
    NonEmbed: '/non-embed',
    NonLoggedIn: '/non-loggedin',
    ClusterStatus: '/cluster-status',
    UserPreference: '/user-preference',
    Login: '/login',
    Answers: '/answers',
    Pinboards: '/pinboards',
    Requester: '/expert-requests/requester',
    Expert: '/expert-requests/expert',
    Answer: '/answer',
    AnswerEdit: '/answer/edit',
    SavedAnswer: '/saved-answer',
    Pinboard: '/pinboard',
    Eureka: '/eureka',
    CreateAiAnswer: '/create-ai-answer',
    AiAnswer: '/ai-answer',
    ConvAssist: '/conv-assist',
    Edge: '/edge',
    NewOnboarding: '/new-onboarding',
    Captain: '/captain',
    Worksheet: '/worksheet',
    WorksheetCreate: '/worksheet/create',
    View: '/view',
    ImportTsl: '/import-tsl',
    TslEditor: '/tsl-editor',
    ImportData: '/importdata',
    LoadData: '/data/importcsv/loaddata',
    ImportCSV: 'data/importcsv',
    ImportedData: '/data/importeddata',
    ImportDataSource: '/importdatasource',
    Insights: '/insights',
    Insight: '/insight',
    FollowHome: '/insights/follow',
    Data: '/data',
    Views: '/data/views',
    DataExplorer: '/data/explore',
    DataLinking: '/data/link',
    RLS: '/data/rls',
    DataGovernance: '/data/data-governance',
    LiveBoardVerification: '/data/liveboard-verification',
    QueryFeedback: '/data/query-feedback',
    FragmentFeedback: '/data/fragment-feedback',
    AppConnections: '/data/app-connections',
    DataSources: '/data/impds',
    Tables: '/data/tables',
    Embrace: '/data/embrace',
    EmbraceConnection: '/data/embrace/connection',
    Dataflow: '/data/dataflow',
    SpotApp: '/data/spotapp',
    SpotAppExport: '/data/spotapp-export',
    Dataset: '/data/dataset',
    DestinationSync: '/data/destination-sync',
    SqlView: '/data/sql-view',
    CreateSchema: '/create-schema',
    ScheduleReport: '/schedule',
    PinboardScheduledReports: '/schedules',
    SchemaViewer: '/schema-viewer/table',
    Embed: '/embed/viz',
    RelatedLink: '/related-link',
    Profile: '/user-preference',
    SearchResults: '/results',
    Snapshot: '/snapshot',
    OnBoarding: '/onboarding',
    Purchase: '/purchase',
    RequestResetPassword: '/requestresetpassword',
    UserManagementUsers: '/admin/user-management/users',
    UserManagementGroups: '/admin/user-management/groups',
    TableJoin: '/data/tables/join',
    SearchAssist: '/search-assist',
    Setup: '/setup',
    MonitorV2: '/monitor',
    AutoWorksheet: '/data/worksheet/create/auto',
    TrainSage: '/data/sage/train',
    AutoAnswer: '/answer/create/auto',
    RequestAccess: '/requestaccess',
    CustomCalendarV2: '/data/custom-calendar',
    DevelopActionsCustomization: '/develop/actionsCustomization',
    BusinssDataModel: '/data/business-data-model',
    Remapping: '/remapping',
    Utilities: '/data/utilities',
    Destination: '/data/destination',
    DestinationType: '/data/destination-sync/auth/type',
    DestinationAuthComplete: '/data/destination-sync/auth/complete',
    ManageSchedulesV2: '/schedules',
    Teams: '/teams',
    TeamsDataUsage: '/teams/data-usage',
    TeamsPlanOptions: '/teams/plan-options',
    TeamsManageSubscription: '/teams/manage-subscription',
    TeamsUpgradeOptions: '/teams/upgrade-options',
    TeamsSpotIQOptions: '/teams/spotiq',
    TeamsStyleCustomizationOptions: '/teams/style-customization',
    TeamsUserManagementOptions: '/teams/user-management',
    TeamsAuthenticationOptions: '/teams/authentication',
    TeamsSystemActivitiesOptions: '/teams/system-activities',
    TeamsMembers: '/teams/members',
    TeamsPendingInvitations: '/teams/pending-invitations',
    Dbt: '/data/dbt',
    DbtIntegration: '/data/dbt-integration',
    SpotAppPublish: '/data/spotapp/publish',
    SpotAppsAdmin: '/data/spotapps-admin',
    OrgsHome: '/orgs',
    OrgsUsers: '/orgs/users',
    OrgsGroups: '/orgs/groups',
    OrgsContent: '/orgs/content',
    OrgsCredit: '/orgs/credit-consumption',
    Demo: '/demo',
};

export const isCurrentOnboardingPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.OnBoarding) !== -1;
};

export const isCurrentNonLoggedInPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.NonLoggedIn) !== -1;
};

export const isCurrentImportTslPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.ImportTsl) !== -1;
};

export const isCurrentTslEditorPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.TslEditor) !== -1;
};

export const isCurrentEmbraceConnectionPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.EmbraceConnection) !== -1;
};

export const isCurrentEmbraceConnectionEditPath = (
    pathname: string,
): boolean => {
    return (
        pathname.indexOf(routePaths.OnBoarding) !== -1 &&
        pathname.indexOf('edit') !== -1
    );
};

export const isCurrentSpotAppExportPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.SpotAppExport) !== -1;
};

export const isCurrentSqlViewPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.SqlView) !== -1;
};

export const isCurrentDestinationTypePath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.DestinationType) !== -1;
};

export const isCurrentDestinationAuthCompletePath = (
    pathname: string,
): boolean => {
    return pathname.indexOf(routePaths.DestinationAuthComplete) !== -1;
};

export const isCurrentSpotappAnalyticsPath = (pathname: string): boolean => {
    return (
        pathname.indexOf('spotapp') !== -1 &&
        pathname.indexOf('/analytics') !== -1
    );
};

export const isCurrentSpotappConnectorAuthCompletePath = (
    pathname: string,
): boolean => {
    return (
        pathname.indexOf(routePaths.SpotApp) !== -1 &&
        pathname.indexOf('auth') !== -1
    );
};

export const isCurrentDbtIntegrationPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.DbtIntegration) !== -1;
};

export const isCurrentSpotAppPublishPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.SpotAppPublish) !== -1;
};

export const isCurrentTableJoinPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.TableJoin) !== -1;
};

export const isCurrentSearchAssistPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.SearchAssist) !== -1;
};

export const isCurrentSharedLiveboardWithoutOnboarding = (
    pathname: string,
    sessionService: SessionService,
): boolean => {
    const singlePinboardRoute = /pinboard\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/i;
    return (
        pathname.slice(1).search(singlePinboardRoute) > -1 &&
        sessionService.isOnboardingEnabled() &&
        !sessionService.hasSeenOnboarding()
    );
};

export const isCurrentAutoWorksheetPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.AutoWorksheet) !== -1;
};

export const isCurrentAutoAnswerPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.AutoAnswer) !== -1;
};

export const isCurrentCustomCalendarPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.CustomCalendarV2) !== -1;
};

export const isCurrentBusinessDataModelPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.BusinssDataModel) !== -1;
};

export const isCurrentSchemaViewerPath = (
    pathname: string,
    sessionService: SessionService,
): boolean => {
    return (
        pathname.indexOf(routePaths.SchemaViewer) > -1 &&
        sessionService.hasDataWorkspacePrivilege()
    );
};

export const isCurrentImportDataPath = (
    pathname: string,
    sessionService: SessionService,
): boolean => {
    return (
        pathname.indexOf(routePaths.ImportData) !== -1 &&
        sessionService.hasDataWorkspacePrivilege()
    );
};

export const isCurrentCreateSchemaPath = (
    pathname: string,
    sessionService: SessionService,
): boolean => {
    return (
        pathname.indexOf(routePaths.CreateSchema) > -1 &&
        sessionService.hasDataWorkspacePrivilege()
    );
};

export const isCurrentRemappingPath = (
    pathname: string,
    sessionService: SessionService,
): boolean => {
    return (
        pathname.indexOf(routePaths.Remapping) > -1 &&
        sessionService.hasDataWorkspacePrivilege()
    );
};

export const isCurrentOrgsPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.OrgsHome) !== -1;
};

export const isCurrentRequestAccessPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.RequestAccess) !== -1;
};

export const isCurrentImportCSVPath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.ImportCSV) !== -1;
};

export const isCurrentPurchasePath = (pathname: string): boolean => {
    return pathname.indexOf(routePaths.Purchase) !== -1;
};

/**
 * TODO: Replace with authenticated-app-view -> isTopNavHidden prop
 */
export const shouldNotShowNavBar = (
    pathname: string,
    sessionService: SessionService,
    navService: NavService,
) => {
    return (
        isCurrentOnboardingPath(pathname) ||
        isCurrentNonLoggedInPath(pathname) ||
        isCurrentImportTslPath(pathname) ||
        isCurrentTslEditorPath(pathname) ||
        isCurrentEmbraceConnectionPath(pathname) ||
        isCurrentEmbraceConnectionEditPath(pathname) ||
        isCurrentSpotAppExportPath(pathname) ||
        isCurrentSqlViewPath(pathname) ||
        isCurrentDestinationTypePath(pathname) ||
        isCurrentDestinationAuthCompletePath(pathname) ||
        isCurrentSpotappAnalyticsPath(pathname) ||
        isCurrentSpotappConnectorAuthCompletePath(pathname) ||
        isCurrentDbtIntegrationPath(pathname) ||
        isCurrentSpotAppPublishPath(pathname) ||
        navService.shouldShowDeepLinkPage() ||
        isCurrentTableJoinPath(pathname) ||
        isCurrentSearchAssistPath(pathname) ||
        isCurrentSharedLiveboardWithoutOnboarding(pathname, sessionService) ||
        isCurrentAutoWorksheetPath(pathname) ||
        isCurrentAutoAnswerPath(pathname) ||
        isCurrentCustomCalendarPath(pathname) ||
        isCurrentBusinessDataModelPath(pathname) ||
        isCurrentSchemaViewerPath(pathname, sessionService) ||
        isCurrentImportDataPath(pathname, sessionService) ||
        isCurrentCreateSchemaPath(pathname, sessionService) ||
        isCurrentRemappingPath(pathname, sessionService) ||
        isCurrentOrgsPath(pathname) ||
        isCurrentRequestAccessPath(pathname) ||
        isCurrentImportCSVPath(pathname) ||
        isCurrentPurchasePath(pathname)
    );
};

/**
 * TODO: should add isExcludedSetupProgressBar prop in route config..
 */
export const isExcludedSetUpProgressBarCanvasStates = (pathname: string) => {
    return (
        pathname.indexOf(canvasStates.LOGIN) !== -1 ||
        pathname.indexOf(canvasStates.ACCOUNT_ACTIVATION) !== -1 ||
        pathname.indexOf(canvasStates.REQUEST_RESET_PASSWORD) !== -1 ||
        pathname.indexOf(canvasStates.RESET_PASSWORD) !== -1 ||
        pathname.indexOf(canvasStates.SLACK_AUTH) !== -1 ||
        pathname.indexOf(canvasStates.EMBED) !== -1 ||
        pathname.indexOf(canvasStates.SETUP) !== -1 ||
        pathname.indexOf(canvasStates.EUREKA) !== -1 ||
        pathname.indexOf(canvasStates.CREATE_AI_ANSWER) !== -1 ||
        pathname.indexOf(canvasStates.AI_ANSWER) !== -1 ||
        pathname.indexOf(canvasStates.EMBRACE_REMAPPING) !== -1 ||
        // pathname.indexOf(canvasStates.IMPORT_DATA) !== -1 ||
        pathname.indexOf(canvasStates.CREATE_SCHEMA) !== -1 ||
        pathname.indexOf(canvasStates.REQUEST_ACCESS) !== -1 ||
        pathname.indexOf(canvasStates.PURCHASE) !== -1 ||
        pathname.indexOf(canvasStates.CONV_ASSIST) !== -1
    );
};

export const isAnswerCreateAutoSetupPage = (
    pathname: string,
    queryString: string,
): boolean => {
    if (pathname.indexOf('answer/create/auto') === -1) {
        return false;
    }

    const urlParams = new URLSearchParams(queryString);
    const origin = urlParams.get('origin');
    return origin === 'setupHub';
};
