/**
 * Utilities for detecting properties of the current browser, and informing the user of unsupported
 * browsers.
 */

import { BrowserInfo, detect } from 'detect-browser';
import { getRenderRoot } from '/@utils/react-base.util';

export enum BrowserName {
    Android = 'android',
    Chrome = 'chrome',
    EdgeLegacy = 'edge', // Old version of Edge before Chromium migration
    EdgeChromium = 'edge-chromium', // New Edge based on Chromium
    Firefox = 'firefox',
    IE = 'ie',
    Safari = 'safari',
    Phantom = 'phantomjs',
}

// TODO(Rifdhan) is this needed?
// Note (sunny): Overriding user agent is currently broken in headless chromium.
// Once it is fixed upstream we should get rid of this special handling
const browser: BrowserInfo = (detect() as BrowserInfo) || {
    name: BrowserName.Chrome,
    version: '80',
    os: 'Windows 10',
    type: 'browser',
};

export const browserName: BrowserName = browser.name as BrowserName;
export const version = browser.version;
export const browserInfo = {
    isIE: browserName === BrowserName.IE,
    isFirefox: browserName === BrowserName.Firefox,
    isSafari: browserName === BrowserName.Safari,
    isChrome: browserName === BrowserName.Chrome,
    isEdgeLegacy: browserName === BrowserName.EdgeLegacy,
    isEdgeChromium: browserName === BrowserName.EdgeChromium,
};

export function setItemInSessionStorage(key: string, value: string) {
    sessionStorage.setItem(key, value);
}

export function redirectTo(url: string) {
    window.location.href = url;
}

export function reload() {
    window.location.reload();
}

export function isAndroid(): boolean {
    return navigator.userAgent.match(/Android/i) !== null;
}

export function loadCssDynamically(cssResourceUrl: string) {
    if (!cssResourceUrl) return;
    const body = document.getElementsByTagName('body')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = cssResourceUrl;
    body.appendChild(link);
}

if (browserInfo.isIE) {
    getRenderRoot().classList.add(BrowserName.IE);
}

if (browserInfo.isFirefox) {
    getRenderRoot().classList.add(BrowserName.Firefox);
}

if (browserInfo.isSafari) {
    getRenderRoot().classList.add(BrowserName.Safari);
}

if (browserInfo.isEdgeLegacy) {
    getRenderRoot().classList.add(BrowserName.EdgeLegacy);
}

if (browserInfo.isEdgeChromium) {
    getRenderRoot().classList.add(BrowserName.EdgeChromium);
}

export function getEncodedContent(rawContent: string) {
    /**
     * btoa requires a "Binary String", which is an 8-bit clean string format.
     * It doesn't work with the standard UTF-16 string format if there are charcodes above 255,
     * so if encoding failes, we can convert the string using encodeURIComponent and do the btoa.
     */
    return btoa(encodeURIComponent(rawContent));
}
