/**
 * All the lifecycle methods in this file would only run
 * in case of stanalone app.
 * Note: They won't run when blink-v2 is used as a library
 * inside another app.
 */

import {
    initializeAppLocale,
    overrideStrings,
    setStringOverrides,
} from '@thoughtspot/i18n';
import { initLogger } from '@thoughtspot/logger';
import { logMicroFrontendsDebugInfo } from '@thoughtspot/microfrontends';
import {
    getFormatLocales,
    getPreferredCurrencyFormat,
    markManuallyLoggedOut,
    onUserLoggedIn,
    onUserLoggedOut,
} from '@thoughtspot/session-service';
import { isLocalizationEnabled } from '@thoughtspot/system-config-service';
import _ from 'lodash';
import {
    FLAGS,
    flags,
    initFlags,
} from '/@services/system/config-service/flags-service';
import { initSessionService } from '/@services/system/config-service/session-service';
import { client } from '../../../apollo-client';
import { resetAllPrivileges } from '../../../routing/privilege-list';
import { startWebStorage } from '../config-service/web-storage-service';
import { useUpdateSessionInfo } from '../login-service';
import {
    initializeCustomCalendars,
    initializeLocaleFormats,
    initializeThirdPartyServices,
    initializeTooltip,
    notifySDKOnAuth,
    setEmbedInitData,
} from './lifecycle-hooks-utils';

/* all async calls which should finish before UI render.
 * If something is important for UI to render, would also be
 * important for some of the other services to get initialized.
 * Thus calling this method before anything else during initialization.
 */
export async function preAppRenderAsync() {
    // Read before editing
    // The function calls which are independent and can be made in parallel
    // should go inside following Promise.all block.
    // Only those function Calls which are dependent and cannot be executed
    // in parallel should be added after Promise.all block
    // in a separate await statement.
    initFlags();
    await Promise.all([useUpdateSessionInfo(), startWebStorage()]);
    await initializeAppLocale((clientLocale?: string) => {
        return getFormatLocales(clientLocale, isLocalizationEnabled());
    });
    overrideStrings();
}

/* contain function calls which are either
 * 1. Synchronous
 * 2. Async calls which are not required to finish before UI render
 */
export function preAppRenderSync() {
    // please do not perform computationally intensive tasks here
    // as it will block the initial render and make app slow to load.
    initializeTooltip();
    initLogger({
        debug: flags.getValue(FLAGS.debug?.name),
        clientLogLevel: flags.getValue(FLAGS.clientLogLevel?.name),
        overrideConsoleLogs: flags.getValue(FLAGS.overrideConsoleLogs?.name),
    });
}

/* all function calls which are not necessarily required
 * before App render can be called here.
 */
export async function postInitialAppRender() {
    logMicroFrontendsDebugInfo();
}

/* following lifecycle hook will be called:
 * 1. On fresh login
 * 2. When session is already authenticated, and user
 *    opens the app in a new tab.
 */
export async function postAuthentication(sessionInfo: any) {
    await initSessionService(sessionInfo);
    resetAllPrivileges();
    await initializeAppLocale(getFormatLocales);

    // Notify SDK with user details. Needs to be after sessionService init
    notifySDKOnAuth();
    const { dateFormatLocale, numberFormatLocale } = getFormatLocales();

    const initialiseLocaleFormatsPromise = initializeLocaleFormats(
        dateFormatLocale,
        numberFormatLocale,
        getPreferredCurrencyFormat(),
    );

    await Promise.all([
        initialiseLocaleFormatsPromise,
        initializeCustomCalendars(),
        client.resetStore(),
    ]);
    initializeThirdPartyServices();
    onUserLoggedIn.publish();
}

/* following lifecycle hook is called on logout
 */
export async function postLogout() {
    resetAllPrivileges();
    markManuallyLoggedOut();
    onUserLoggedOut.publish();
    await Promise.all([client.clearStore(), initSessionService(null)]);
}

export async function onEmbedInit(data: any) {
    const stringOverrides = data?.customisations?.content?.strings;
    setStringOverrides(stringOverrides);
    await setEmbedInitData(data);
}
