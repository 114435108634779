/**
 * Error Boundary component
 * Boundaries are analogous to catch block in a try-catch
 * A boundary could subscribe to a set of event, if an error occurs in
 * a child component then its propagated to the nearest parent Error Boundary
 * If that Boundary can handle the thrown error then its captured at that
 * boundary, if not its propagated upwards
 */

import {
    ErrorContext,
    ErrorObject,
    IDefaultErrorCodes,
} from '@thoughtspot/blink-context';
import _ from 'lodash';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

const useDeepCompareMemoize = (value: any) => {
    const ref = useRef();
    if (!_.isEqual(value, ref.current)) {
        ref.current = value;
    }
    return ref.current;
};

const useDeepCompareCallback = (
    callback: (errs: ErrorObject<any | IDefaultErrorCodes>[]) => void,
    dependencies: Array<any>,
) => {
    return useCallback(callback, dependencies.map(useDeepCompareMemoize));
};

/** **********************************************************
 * ERROR PROPAGATION LOGIC AND STATE
 *********************************************************** */

/**
 * Hook function encapsulating all the logic for the Error boundary
 * @param handledErrors
 */

const useGetErrorBoundaryState = <T,>(
    handledErrors: (T | IDefaultErrorCodes)[],
) => {
    // We create a copy of the handled errors first time the hook
    // is called, so that for further rerenders of the caller, the
    // reference to the handled errors doesn't change, this is because
    // handledErrors in an array, and since the default react behaviuor is
    // to do shallow compare, the handledErrors would appear to change
    // with every render.
    // If handledErrors need to be changed dynamicaaly we will need to
    // use a deep compare effect here (for eg. useDeepCompareEffect from react-use)
    const [handledErrorsCopy, setHandledErrorsCopy] = useState<
        (T | IDefaultErrorCodes)[]
    >(handledErrors);

    // Access to the parent ErrorContext
    // (next immediate ErrorContent upwards in the DOM heirarchy)
    const parentCtx = useContext(ErrorContext);

    // State of storing error data for the Boundary
    const [errors, setErrors] = useState<ErrorObject<T | IDefaultErrorCodes>[]>(
        [],
    );

    // State for storing the combined errors of the current context + all
    // its predecessors
    const [combinedErrors, setCombinedErrors] = useState<
        ErrorObject<T | IDefaultErrorCodes>[]
    >([]);

    // Effect to update the combined errors whenever the error in current
    // context or parent context chnages
    useEffect(() => {
        const newErrors = [...parentCtx.combinedErrors, ...errors];
        if (!_.isEqual(combinedErrors, newErrors)) {
            setCombinedErrors(newErrors);
        }
    }, [errors, parentCtx.combinedErrors]);

    // Callback fn to post an Error and propagate it through all the
    // Error boundaries, this will be called from the source of the
    // error (for eg. Apollo hooks)
    const postError = useDeepCompareCallback(
        (errs: ErrorObject<T | IDefaultErrorCodes>[]) => {
            let handleableErrors: ErrorObject<T | IDefaultErrorCodes>[] = [];
            let nonHandleableErrors: ErrorObject<T | IDefaultErrorCodes>[] = [];

            if (handledErrors.indexOf('*') !== -1) {
                handleableErrors = errs;
                nonHandleableErrors = [];
            } else {
                handleableErrors = errs.filter(err => {
                    return handledErrorsCopy.includes(err.code);
                });
                nonHandleableErrors = _.difference(errs, handleableErrors);
            }
            if (!_.isEqual(errors, handleableErrors)) {
                setErrors(handleableErrors);
            }
            if (nonHandleableErrors.length !== 0) {
                parentCtx.postError(nonHandleableErrors);
            }
        },
        [handledErrors, handledErrorsCopy, errors, parentCtx],
    );

    const resetError = useCallback(() => {
        parentCtx.resetError();
        setErrors([]);
    }, []);

    return {
        errors,
        combinedErrors,
        postError,
        resetError,
    };
};

/** **********************************************************
 * COMPONENT
 *********************************************************** */

/**
 * Props for Error boundary component
 */
export interface Props<T> {
    children:
        | React.ReactNode
        | ((
              error: ErrorObject<T | IDefaultErrorCodes>[],
              resetError: () => void,
          ) => React.ReactNode);
    handledErrors: (T | IDefaultErrorCodes)[];
}

/**
 * Error Boundary Component
 * @param children - Children component
 * @param handledErrors - List of errors handled by error boundary
 * @param name - Optional name for the error boundary
 *               (combinedErrors will have the name of boundary, where the
 *                error is capture if provided)
 */
const ErrorBoundary = <T,>({ children, handledErrors }: Props<T>) => {
    const {
        errors,
        combinedErrors,
        postError,
        resetError,
    } = useGetErrorBoundaryState<T | IDefaultErrorCodes>(handledErrors);

    const getErrorBoundaryState: any = useMemo(() => {
        return {
            errors,
            combinedErrors,
            postError,
            resetError,
        };
    }, [errors, combinedErrors, postError, resetError]);

    return (
        <ErrorContext.Provider value={getErrorBoundaryState}>
            {typeof children === 'function'
                ? children(errors, resetError)
                : children}
        </ErrorContext.Provider>
    );
};

export { ErrorBoundary };
