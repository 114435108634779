// embed utilities required by react shell while app initialization
import { blinkConstants } from '@thoughtspot/blink-constants';
import { inIFrame } from '@thoughtspot/browser-util';
import { getQueryParamsAndQueryString } from '@thoughtspot/env-flags';
import { versionCompare } from '@thoughtspot/js-util';

export const isEmbeddedApp = () => {
    return inIFrame() || window.location.href.indexOf('/embed/') > -1;
};

export function isAppInIFrame(): boolean {
    return inIFrame();
}

export type EmbedParams = { [key: string]: any };

export const getQueryParams = (qParams: any): EmbedParams => {
    const queryParams = {};
    Object.keys(qParams).forEach(key => {
        if (qParams[key].length === 1) {
            const value = qParams[key][0];
            const decodedValue = decodeURIComponent(value);
            try {
                if (/^(\d+|\d+\.\d+)$/.test(decodedValue))
                    queryParams[key] = decodedValue;
                else queryParams[key] = JSON.parse(decodedValue);
            } catch (e) {
                // TODO: handle error
                queryParams[key] = decodedValue;
            }
        } else {
            queryParams[key] = qParams[key];
        }
    });
    return queryParams;
};

export const cacheFunctionOnly = (fn: any) => {
    const cache = new Map();

    return (...args: any[]) => {
        if (!cache.has(fn)) {
            cache.set(fn, fn(...args));
        }

        return cache.get(fn);
    };
};

// eslint-disable-next-line no-underscore-dangle
export const _getEmbedQueryParams = (): EmbedParams => {
    return getQueryParams(getQueryParamsAndQueryString().queryParamsMultiValue);
};

export const getEmbedQueryParams = cacheFunctionOnly(_getEmbedQueryParams);

export const sdkVersion = (): string => {
    const queryParams = getEmbedQueryParams();
    // eslint-disable-next-line dot-notation
    return queryParams['sdkVersion'];
};

/** To get hostURL/parentOrigin of the iframe from SDK */
export const parentOrigin = (): string => {
    const queryParams = getEmbedQueryParams();
    // eslint-disable-next-line dot-notation
    return queryParams['hostAppUrl'];
};

export const parentProtocol = (): string => {
    const queryParams = getEmbedQueryParams();
    return queryParams.hostAppProtocol;
};

/** This is to skip the promise if the sdk version is less than v17.0.0 */
export const versionToBeSkipped = (): boolean => {
    return (versionCompare(sdkVersion(), '1.17.0') as number) >= 0;
};

export const EventType = blinkConstants.embedEvents;

export enum eventErrorType {
    Api = 'API',
    Fullscreen = 'FULLSCREEN',
    SingleValueFilter = 'SINGLE_VALUE_FILTER',
    NonExistFilter = 'NON_EXIST_FILTER',
    NoActivePersonalisedViewsError = 'NO_ACTIVE_PERSONALISED_VIEWS_ERROR',
    InvalidDateValue = 'INVALID_DATE_VALUE',
    InvalidOperator = 'INVALID_OPERATOR',
    WrongVizId = 'WRONG_VIZ_ID',
    NotAWorksheet = 'NOT_A_WORKSHEET',
    NoFilterableColumnPresent = 'NO_FILTERABLE_COLUMN_PRESENT',
    filterExistsOrNoAttributeColumn = 'FILTER_EXISTS_OR_NO_ATTRIBUTE_COLUMN',
}

/* Triggered embed event status */
export enum EventStatus {
    Start = 'start',
    End = 'end',
}
