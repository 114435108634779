import { Microfrontend } from '@thoughtspot/microfrontends';
import {
    loadMicroFrontendComponent,
    loadMicroFrontendFunction,
} from './microfrontend-wrapper';

enum COMSComponents {
    ComsPreferences = 'ComsPreferences',
    UserMenuContainer = 'UserMenuContainer',
    AddToSlackContainer = 'AddToSlackContainer',
    getValidComsToken = 'getValidComsToken',
}

export const ComsPreferences = loadMicroFrontendComponent(
    Microfrontend.COMS,
    COMSComponents.ComsPreferences,
);

export const UserMenuContainer = loadMicroFrontendComponent(
    Microfrontend.COMS,
    COMSComponents.UserMenuContainer,
);

export const AddToSlackContainer = loadMicroFrontendComponent(
    Microfrontend.COMS,
    COMSComponents.AddToSlackContainer,
);

export const getValidComsToken = async (operationName: string) => {
    const getValidComsTokenFn = await loadMicroFrontendFunction(
        Microfrontend.COMS,
        COMSComponents.getValidComsToken,
    );
    return getValidComsTokenFn(operationName);
};
