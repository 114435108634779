import { getEventPath } from '@thoughtspot/browser-util';
import { UserAction, userActionTypeEnum } from '@thoughtspot/metrics';
import _ from 'lodash';

const eventTypeToUserActionType = {
    click: userActionTypeEnum.CLICK,
    contextmenu: userActionTypeEnum.RIGHT_CLICK,
    dblclick: userActionTypeEnum.DOUBLE_CLICK,
};

let collectEvent: any = (...args: any[]): void => undefined;

function getClassString(el: HTMLElement) {
    const className = el && el.getAttribute && el.getAttribute('class');
    const classString = (className || '')
        .trim()
        .split(' ')
        .join('.')
        .substr(0, 255);
    return classString ? `.${classString}` : '';
}

const uiEventHandler = {
    handleEvent: (e: MouseEvent) => {
        const userActionType = eventTypeToUserActionType[e.type];
        const userAction = new UserAction(userActionType);
        const eventPath = getEventPath(e);
        const path = _(eventPath)
            .map(elem => getClassString(elem))
            .reverse()
            .compact()
            .value();
        collectEvent(userAction, {
            targetClass: getClassString(e.target as HTMLElement),
            eventHandlerClass: '',
            trackId: '',
            path,
            offsetX: e.offsetX,
            offsetY: e.offsetY,
        });
    },
};

// All UI events we are tracking
document.addEventListener('click', uiEventHandler, true);
document.addEventListener('contextmenu', uiEventHandler, true);
// Note(Rifdhan): one dblclick may also fire two click events before it (this is just the behavior
// of the browser, and varies by browser)
document.addEventListener('dblclick', uiEventHandler, true);

export function initAutoTracking(_collectEvent: any) {
    collectEvent = _collectEvent;
}
