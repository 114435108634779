// All the services can be accessed via hooks from app context
// This below import type is supported in prettier 2.0
import {
    BlinkContext,
    BlinkContextOptions,
    useBlinkContext,
    useFeatureFlags,
} from '@thoughtspot/blink-context';
// eslint-disable-next-line prettier/prettier
import type { SessionService } from '@thoughtspot/session-service';
import React from 'react';

export { SessionService };

export type AppContextOptions = BlinkContextOptions

export const useAppContext = (): BlinkContextOptions =>
    useBlinkContext();

export const AppContextProvider: React.FC<BlinkContextOptions> = ({
    children,
    metricsService,
    sessionService,
    systemConfigService,
    customStylingService,
    navService,
    webStorageService,
    blinkHooks,
}) => {
    const [_navService, setNavService] = React.useState(navService);
    const { flags: featureFlags } = useFeatureFlags();
    return (
        <BlinkContext.Provider
            value={{
                metricsService,
                sessionService,
                systemConfigService,
                customStylingService,
                navService: _navService,
                setNavService,
                webStorageService,
                blinkHooks,
                featureFlags,
            }}
        >
            {children}
        </BlinkContext.Provider>
    );
};
