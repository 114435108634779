import { NavService } from '@thoughtspot/blink-context';
import {
    DataBaseMode,
    SessionService,
    UserPersona,
} from '@thoughtspot/session-service';
import { isEmbeddedUseCase } from '@thoughtspot/system-config-service';
import { isAppInIFrame } from '/@utils/embed-base.util';
import * as routesUtil from '../routes-util';

/**
 * Util that tells us whether setup tab/link is accessible or not
 */
export const isAnalystSetupHubAllowed = (
    sessionService: SessionService,
): boolean => {
    // Setup tab/link should be allowed when:
    // 1. User has Admin or Data Management privilege
    // 2. AO is enabled
    // 3. User is an analyst who has not completed setup
    // 4. Should not be embedded use case
    return (
        sessionService.hasAnalystEquivalentPriveleges() &&
        sessionService.isAnalystOnboardingEnabled() &&
        sessionService.isOnboardingEnabled() &&
        sessionService.getDataBaseMode() !== DataBaseMode.FALCON &&
        sessionService.getUserPersona() === UserPersona.ANALYST &&
        !sessionService.getIsAnalystOnboardingCompleted() &&
        !isEmbeddedUseCase()
    );
};

export const showSetupV2ProgressBar = (
    pathname: string,
    sessionService: SessionService,
    navService: NavService,
) => {
    if (
        !sessionService.isReactshellEnabled() ||
        routesUtil.shouldNotShowNavBar(pathname, sessionService, navService)
    ) {
        return false;
    }

    if (
        !routesUtil.isExcludedSetUpProgressBarCanvasStates(pathname) &&
        isAnalystSetupHubAllowed(sessionService) &&
        sessionService.isSetupV2Enabled() &&
        !navService.shouldShowDeepLinkPage() &&
        !isAppInIFrame()
    ) {
        return true;
    }

    return false;
};
