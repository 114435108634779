import { useTranslation } from '@thoughtspot/i18n';
import { create } from '@thoughtspot/logger';
import { isPendoAllowed } from '@thoughtspot/pendo';
import { LoadingIndicator } from '@thoughtspot/radiant-react/widgets/loading-indicator';
import React, { Suspense, useEffect, useState } from 'react';
import { useLocation, useMatches } from 'react-router';
import { useAppContext } from '/@contexts/global-app-context';
import {
    useAddUserToModeMutation,
    useGetAnalystStudioAccessInfoLazyQuery,
} from '/@services/analyst-studio/analyst-service';
import {
    isCurrentPrintViewPath,
    removeOverrideOrgId,
} from '/@services/system/nav-service/nav-service';
import {
    Application,
    ApplicationList,
    Applications,
} from '../../routing/routes';
import { UserMenuContainerWithInvite } from '../user-menu/user-menu.container';
import { ClassicNavExperience } from './classic-nav-view/classic-nav.container';
import { useAnalystStudioMutation } from './custom-action-hooks/useAnalystStudioCustomHook';
import {
    GlobalNav,
    NavLinkCallback,
    NavLinkConfig,
    NavLinkWithTitleIconAndCallback,
} from './global-nav';

const Logger = create('top-nav');

const HomeSearchBarContainer = React.lazy(() =>
    import('../home/home-search-bar/home-search-bar.container'),
);

interface NavContainerProps {
    isCopilotEnabled?: boolean;
}

const NavContainer: React.FC<NavContainerProps> = ({
    isCopilotEnabled = false,
}) => {
    const { navService, sessionService, systemConfigService } = useAppContext();
    const {
        getAccessToken,
        getOktaUserId,
        getUserPrivileges,
        getCurrentOrgId,
    } = sessionService;
    const { getIdpDomain, getClientId } = systemConfigService;
    const { t } = useTranslation();
    const matches = useMatches();
    const location = useLocation();

    const shouldShowPendoHelp =
        sessionService.isPendoHelpEnabled() &&
        isPendoAllowed(isCurrentPrintViewPath());

    const currentAppRoute = matches.find(
        match => (match.handle as any)?.handle,
    );
    const [availableApplications, setAvailableApplications] = useState<
        Application[]
    >([]);
    const [isEurekaPath, setIsEurekaPath] = useState(false);
    const [activeApplication, setActiveApplication] = useState<Application>(
        ApplicationList[Applications.INSIGHTS],
    );
    const [applicationSwitcherConfig, setApplicationSwitcherConfig] = useState<
        NavLinkConfig[]
    >([]);

    const { onAnalystStudioClicked } = useAnalystStudioMutation(
        getAccessToken,
        getOktaUserId,
        getUserPrivileges,
        getCurrentOrgId,
        useAddUserToModeMutation,
        useGetAnalystStudioAccessInfoLazyQuery,
        getIdpDomain,
        getClientId,
    );

    const goToApplicationHome = (applicationID: string) => {
        if (
            applicationID === 'admin' &&
            sessionService.isOrgUiEnabled() &&
            sessionService.isOrgPerUrlEnabled()
        ) {
            const currentPathBeforeHash = `${
                window.location.href.split('#')[0]
            }#`;
            let path: string =
                currentPathBeforeHash +
                (`${ApplicationList[applicationID].homePath}` || '/');
            path = removeOverrideOrgId(path);
            window.location.replace(path);
        } else if (applicationID === Applications.ANALYST_STUDIO) {
            onAnalystStudioClicked();
        } else if (
            navService.getCurrentPathByLocationHash(false) ===
            ApplicationList[applicationID].homePath
        ) {
            window.location.reload();
        } else {
            navService.goToUrl(`${ApplicationList[applicationID].homePath}`);
        }
    };

    const getTargetForApplication = (applicationId: string) => {
        const titleIconId = ApplicationList[applicationId].titleIconId;
        let target = {
            callback: () => {
                goToApplicationHome(applicationId);
            },
            routerURI: `${ApplicationList[applicationId].homePath}`,
        } as NavLinkCallback;
        if (titleIconId && titleIconId !== null && titleIconId !== '') {
            target = {
                ...target,
                titleIconId,
            } as NavLinkWithTitleIconAndCallback;
        }
        return target;
    };

    useEffect(() => {
        if (currentAppRoute.id !== Applications.CORE) {
            setActiveApplication(ApplicationList[currentAppRoute.id]);
        }
    }, [ApplicationList, currentAppRoute]);

    useEffect(() => {
        const pathName = (location.pathname as string) || '';
        setIsEurekaPath(pathName.includes('/insights/eureka'));
    }, [location, activeApplication]);

    useEffect(() => {
        (async () => {
            const newList: Application[] = [];

            await Promise.all(
                Object.values(ApplicationList).map(
                    async (application, index) => {
                        if (application.id === Applications.CORE) {
                            return;
                        }

                        try {
                            await Promise.all(application.privileges);
                            newList[index] = application;
                        } catch (e) {
                            Logger.warn(e);
                            newList[index] = null;
                        }
                    },
                ),
            );

            setAvailableApplications(newList.filter(app => !!app));
        })();
    }, [ApplicationList]);

    useEffect(() => {
        const applicationConfig = availableApplications.map(
            application =>
                ({
                    name: t(application.title as any),
                    id: application.id,
                    description: t(application.description as any),
                    icon: application.icon,
                    target: getTargetForApplication(application.id),
                    pageContext: undefined,
                } as NavLinkConfig),
        );
        setApplicationSwitcherConfig(applicationConfig);
    }, [t, availableApplications]);

    const showSearchBar =
        sessionService.isEurekaEnabled() || sessionService.isSaaSCluster();

    // with compact mode, we'll always render the SearchBar in Global Nav
    return sessionService.isModularHomeExperienceEnabled() ?? true ? (
        <GlobalNav
            sessionService={sessionService}
            renderSearchBar={showSearchBar && !isEurekaPath}
            homeButtonConfig={{
                name: '',
                target: {
                    callback: () => goToApplicationHome(activeApplication.id),
                    routerURI: `${
                        ApplicationList[activeApplication.id].homePath
                    }`,
                } as NavLinkCallback,
            }}
            activeApplicationID={activeApplication.id}
            applicationSwitcherConfig={applicationSwitcherConfig}
            shouldShowPendoHelp={shouldShowPendoHelp}
            isCopilotEnabled={isCopilotEnabled}
        />
    ) : (
        <ClassicNavExperience
            searchBar={
                showSearchBar &&
                !isEurekaPath && (
                    <Suspense
                        fallback={
                            <LoadingIndicator.Global>
                                <></>
                            </LoadingIndicator.Global>
                        }
                    >
                        <HomeSearchBarContainer isInClassicNav />
                    </Suspense>
                )
            }
            userProfile={<UserMenuContainerWithInvite onEmailAdd={null} />}
            applicationSwitcherConfig={applicationSwitcherConfig}
            shouldShowPendoHelp={shouldShowPendoHelp}
        />
    );
};

export default NavContainer;
