import { useMutation } from '../../utils/error-handling/apollo-hooks-wrappers/custom-apollo-hooks';
import {
    MetadataType,
    UpdateSetupStateDocument,
    UpdateSetupStateMutation,
    UpdateSetupStateMutationVariables,
    useGetSearchAssistContentLazyQuery as _useGetSearchAssistContentLazyQuery,
    useGetSearchAssistContentQuery as _useGetSearchAssistContentQuery,
    useGetSetupStateLazyQuery as _useGetSetupStateLazyQuery,
    useGetSetupStateQuery as _useGetSetupStateQuery,
    useGetUserPropertiesProtoLazyQuery,
} from '../generated/graphql-types';

export const useGetSetupState = () => {
    const { data, error, loading } = _useGetSetupStateQuery({
        fetchPolicy: 'network-only',
    });

    return {
        error,
        loading,
        data: data?.getSetupState,
    };
};

export const useGetSetupStateLazyQuery = () => {
    const [
        getSetupState,
        { data, error, loading },
    ] = _useGetSetupStateLazyQuery({
        fetchPolicy: 'network-only',
    });

    return {
        getSetupState,
        error,
        loading,
        data: data?.getSetupState,
    };
};

export const useUpdateSetupStateMutation = () => {
    const [updateSetupState, { data, error, loading }] = useMutation<
        UpdateSetupStateMutation,
        UpdateSetupStateMutationVariables
    >(UpdateSetupStateDocument);

    return {
        data: data?.Onboarding__updateSetupState,
        error,
        loading,
        updateSetupState: (type: MetadataType, dataSourceId: string) =>
            updateSetupState({
                variables: {
                    type,
                    dataSourceId,
                },
            }),
    };
};

export const useGetSearchAssistContentQuery = () => {
    const { data, error, loading } = _useGetSearchAssistContentQuery({
        fetchPolicy: 'network-only',
    });

    return {
        error,
        loading,
        data: data?.getSearchAssistContent,
    };
};

export const useGetSearchAssistContentLazyQuery = () => {
    const [
        getSearchAssistContent,
        { data, error, loading },
    ] = _useGetSearchAssistContentLazyQuery({
        fetchPolicy: 'network-only',
    });

    return {
        error,
        loading,
        data: data?.getSearchAssistContent,
        getSearchAssistContent,
    };
};
